import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MantineProvider } from '@mantine/core'
import { memo } from 'react'
import { useUpdateEffect } from 'react-use'
import { useSnapshot } from 'valtio'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { fontWeight500, fontWeight600 } from '~/css/font'
import { scrollbar2Css } from '~/css/scrollbarCss'
import { useIntervalNow } from '~/hooks/useIntervalNow'
import {
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators'
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn'
import { useChartingSymbol } from '~/modules/SDK/chart4/hooks/useChartingSymbol'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useStockToFutureMapper } from '~/modules/SDK/Symbol/useCommonSymbolList'
import { SignalWallTypes } from '~/modules/signal-wall/SignalWallTypes'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { store } from '~/pages/heineken_template/_private/store'
import { indicatorGroup } from './south_initStrategies'
import { south_store } from './south_store'
import { styleds } from './styleds'
import { getEntrySignals, getSignalsBySymbol, setPositionInfo } from './south_utils'
import {
  useCommonSymbolListStore,
  StockFutures,
} from '~/modules/SDK/Symbol/useCommonSymbolListStore'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { South_StrategySelector } from './south_StrategySelector'

export const South_SidePane1 = memo<ReactProps>(function South_SidePane1() {
  const colorTheme = useThemeStore(state => state.theme)
  const charting = useSnapshot(store.charting)
  const signalsState = useSnapshot(south_store.signals.store)
  const selectedStrategy = useSnapshot(south_store).selectedStrategy

  useUpdateEffect(() => {
    south_store.signals.fetchAndUpdate()
  }, [signalsState.from, signalsState.to, selectedStrategy])

  useIntervalNow(() => {
    south_store.signals.fetchAndUpdate()
  }, 1000 * 30)

  if (south_store.symbol === '') {
    const signals = getSignalsBySymbol(
      charting.symbol,
      south_store.signals.store.data,
    ) as SignalWallTypes.Datum[]
    setPositionInfo(charting.symbol, signals)
  }
  const signals = getEntrySignals(signalsState.data as SignalWallTypes.Datum[], selectedStrategy)
  console.log(signals.length)
  return (
    <MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar>
        <TitleSymbolQuote.Default symbol={charting.symbol} />

        <styleds.IntradayTrendChart>
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={10}
            ticksHeight={16}
            transparency={0.4}
            priceTicksSize={9}
            priceTicksMargin={-15}
          />
        </styleds.IntradayTrendChart>

        <styleds.SimpleQuoteListTXF>
          <SimpleQuoteListInteractWithChart
            data={['TX-1', 'TXAM-1']}
            chart={store.charting}
          />
        </styleds.SimpleQuoteListTXF>

        <styleds.TitleString>南哥量化當沖選股期</styleds.TitleString>

        <styleds.SimpleQuoteListInteract>
          <south_store.signals.DatePicker />
          <South_StrategySelector
            onChanged={strategyId => {
              south_store.selectedStrategy = strategyId
            }}
          />
          <SymbolPositionQuote
            data={signals}
            chart={store.charting}
            indicators={indicatorGroup}
            handleClick={symbol => {
              setPositionInfo(symbol, getSignalsBySymbol(symbol, south_store.signals.store.data))
            }}
          />
        </styleds.SimpleQuoteListInteract>
      </styleds.SideBar>
    </MantineProvider>
  )
})

export type DatumOfSouth = {
  strategy_name: string
  stock_symbol: string
  future_price: number | string
  stock_price: number | string
  current_position: number | string
  future_stoploss: number | string
}

export const SymbolPositionQuote = memo<
  ReactProps<{
    data: SignalWallTypes.Datum[]
    indicators?: ChartTypes.Indicator[]
    chart: ChartTypes.ChartingV2OrV4
    handleClick?: (symbol: string) => unknown
  }>
>(function SymbolPositionQuote(props) {
  const data = props.data

  const currentSymbol = useChartingSymbol(props.chart)()
  const stockFutureSymbols = useCommonSymbolListStore.getState().stockFuturesMapper as StockFutures

  const changeIndicators = useChartingChangeIndicatorsFn(props.chart)
  const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.chart)

  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        width: 100%;
        height: 100%;
        gap: 4px;
        ${scrollbar2Css};
      `}
    >
      {data.length === 0 && <div css={fill_vertical_all_center}>目前沒有資料或選擇其他日期</div>}
      {data.map((signal, index) => {
        const active = signal.symbol === currentSymbol
        return (
          <div
            css={css`
              width: 100%;
              height: auto;
            `}
            key={index}
            onClick={event => {
              props.handleClick?.(signal.symbol)

              if (currentSymbol !== signal.symbol) {
                changeSymbolInterval({ symbol: stockFutureSymbols[signal.symbol] + '-1' })
              }

              if (props.indicators) {
                changeIndicators(props.indicators)
              }
            }}
          >
            <SymbolPositionQuoteBody
              data={signal}
              active={active}
            />
          </div>
        )
      })}
    </div>
  )
})

export const SymbolPositionQuoteBody = memo<
  ReactProps<{ data: SignalWallTypes.Datum; active: boolean }>
>(function SymbolPositionQuoteBody(props) {
  const data = props.data
  const signalID = data.signal_id
  const signalType = '量化當沖選股期' //data.signal_data_type
  const symbol = data.symbol
  const datetime = data.datetime
  const message = data.message

  //DatumOfSouth給一下type 原本的type error
  const signalData = data.signal_data as DatumOfSouth
  const strategyName = signalData?.strategy_name ?? ''
  const position = signalData?.current_position ?? 0
  const entryPrice = signalData?.future_price ?? 0
  const stoplossPrice = signalData?.future_stoploss ?? 0
  const stockPrice = signalData?.stock_price ?? 0

  //----元件內容----
  const futuresDisctionary = useStockToFutureMapper()

  const positionLong = position === '1'
  const positionShort = position === '-1'
  const positionString = () => {
    if (positionLong) {
      return '多'
    } else if (positionShort) {
      return '空'
    } else {
      return '無'
    }
  }

  return (
    <bodyStyleds.Container
      active={props.active}
      className={`${SymbolPositionQuoteBodyClasses.container}`}
    >
      {/* <bodyStyleds.TradeButton>下單</bodyStyleds.TradeButton> */}

      <bodyStyleds.TitleContent>
        <bodyStyleds.PositionTip position={position}>{positionString()}</bodyStyleds.PositionTip>
        {signalType} {strategyName}
      </bodyStyleds.TitleContent>

      <bodyStyleds.NameContent>
        <SymbolName symbol={symbol} />期 {symbol} {futuresDisctionary?.[symbol]}
      </bodyStyleds.NameContent>

      <bodyStyleds.QuoteContent>
        <bodyStyleds.QuoteItem>
          <bodyStyleds.SymbolTypeTip
            color='#6a8eae'
            className={`${SymbolPositionQuoteBodyClasses.symbolTypeButton}`}
          >
            期
          </bodyStyleds.SymbolTypeTip>
          {entryPrice}
        </bodyStyleds.QuoteItem>
        <bodyStyleds.QuoteItem>
          <bodyStyleds.SymbolTypeTip
            color='#777777'
            className={`${SymbolPositionQuoteBodyClasses.symbolTypeButton}`}
          >
            現
          </bodyStyleds.SymbolTypeTip>
          {stockPrice}
        </bodyStyleds.QuoteItem>
      </bodyStyleds.QuoteContent>

      <bodyStyleds.DefendPriceContent>停損參考: {stoplossPrice}</bodyStyleds.DefendPriceContent>

      <bodyStyleds.DateTimeContetn>
        {new Date(new Date(datetime).getTime() + 8 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16)
          .replace('T', ' ')}
      </bodyStyleds.DateTimeContetn>
    </bodyStyleds.Container>
  )
})

const baseCss = css`
  ${fill_horizontal_cross_center};
  width: 100%;
`

export const bodyStyleds = {
  Container: styled.div<{ active: boolean }>`
    ${fill_vertical_all_center};
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(20%, 5);
    row-gap: 4px;
    width: 100%;
    height: auto;
    background-color: ${options => (options.active ? '#4a4a4a' : '#353535')};
    border: 1px solid ${options => (options.active ? '#cccccc' : '#777777')};
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    &:hover {
      background-color: #3f3f3f;
      border: 1px solid #aaaaaa;
      transition: 0.3s;
    }
  `,
  TradeButton: styled.div`
    ${fill_vertical_all_center};
    ${fontWeight500};
    grid-column: 2;
    grid-row: 1 / 3;
    background-color: #777777;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #888888;
      transition: 0.2s;
    }
    &:active {
      background-color: #999999;
    }
  `,
  TitleContent: styled.div`
    ${baseCss};
    ${fontWeight500};
    gap: 8px;
    grid-column: 1 / 3;
    grid-row: 1;
  `,
  NameContent: styled.div`
    ${baseCss};
    ${fontWeight500};
    grid-column: 1;
    grid-row: 2;
  `,
  PositionTip: styled.div<{ position: number | string }>`
    ${fill_vertical_all_center};
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: ${options =>
      options.position === '1' ? '#ff0000' : options.position === '-1' ? '#00aa00' : '#777777'};
  `,
  SymbolTypeTip: styled.div<{ color: string }>`
    ${fill_vertical_all_center};
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background-color: ${options => options.color};
    font-size: 12px;
    color: #ffffff;
  `,
  QuoteContent: styled.div`
    ${fill_horizontal_cross_center};
    grid-column: 1 / 3;
    grid-row: 3;
  `,
  QuoteItem: styled.div`
    ${baseCss};
    color: #d5b86f;
    ${fontWeight600};
  `,
  DefendPriceContent: styled.div`
    ${baseCss};
    grid-column: 1;
    grid-row: 4;
  `,
  DateTimeContetn: styled.div`
    ${baseCss};
    grid-column: 2;
    grid-row: 2;
  `,
}

export const SymbolPositionQuoteBodyClasses = {
  container: `InformationForm-container`,
  symbolTypeButton: `InformationForm-symbolTypeButton`,
}
