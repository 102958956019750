import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { stockRatioStore } from './StockRatioGraph'
import { stockScoreSensitivityStore } from './StockScoreRankSlider'
import { stockScoreSortStore } from './StockScoreSort'
import { StockScoreTable } from './StockScoreTable'
import { useStkTradeInfoResource } from './useStkTradeInfoResource'

export const StockVolumeListTable = memo<ReactProps<{ changeSymbol?: boolean; data: string[] }>>(
  function StockVolumeListTable(props) {
    const sensitivityState = useSnapshot(stockScoreSensitivityStore)
    const sortState = useSnapshot(stockScoreSortStore)

    /** 股期商品近遠月分數api */
    const stkTradeInfoData = useStkTradeInfoResource()
    /** 組成新的陣列格式 */
    const stkTradeInfoArray = Object.entries(stkTradeInfoData.data).map(([key, value]) => ({
      name: key + '-1',
      near_month: value.near_month,
      next_month: value.next_month,
      total: Number(value.near_month) + Number(value.next_month),
    }))

    /** 使用自選商品或自訂條件的商品 */
    //props.data
    const originData = props.data ?? []

    /** (權值前30名與近遠月商品match) */
    const matchData = stkTradeInfoArray.filter(item => originData?.includes(item.name))

    /** 預設** 依照名稱排列順序 */
    const data = matchData.sort((a, b) => originData.indexOf(a.name) - originData.indexOf(b.name))
    /** 近遠月之和正值家數 */
    const upCount = matchData.filter(s => s.total > 0)
    /** 近遠月之和負值家數 */
    const downCount = matchData.filter(s => s.total < 0)

    //排序 default desc asc
    if (sortState.sortType === 'default') {
      data
    }
    if (sortState.sortType === 'desc') {
      data.sort((a, b) => b.total - a.total)
    }
    if (sortState.sortType === 'asc') {
      data.sort((a, b) => a.total - b.total)
    }

    stockRatioStore.up = upCount.length
    stockRatioStore.down = downCount.length
    stockRatioStore.total = matchData.length

    return (
      <StockScoreTable
        data={data}
        rank={sensitivityState.value === 500 ? 1000 : sensitivityState.value}
        changeSymbol={props.changeSymbol}
      />
    )
  },
)
