export const calculateIndicatorValue = (
  resolution: '1W' | '1D' | '5' | '1',
  bias: number,
  percentR: number,
  rsi: number,
  kValue: number,
): number =>
  resolution === '1W'
    ? (bias + percentR + rsi + kValue) / 3
    : resolution === '1D'
    ? (3 * bias + percentR + rsi + kValue) / 3
    : resolution === '5' || resolution === '1'
    ? (30 * bias + percentR + rsi + kValue) / 3
    : NaN
