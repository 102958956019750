import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const stocknerve_as1 = createIndicator({
  id: 'stocknerve-as1',
  displayName: 'stocknerve_as1',
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#goodway_sfChip960'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      /** Get chip data */
      this._context.select_sym(1)
      const bvav1 = this.ohlc.open
      const tbta1 = this.ohlc.high
      const bvav2 = this.ohlc.low
      const tbta2 = this.ohlc.close

      /** Calculate indicator */
      const aa2 = bvav1 - tbta1 + bvav2 - tbta2

      const bb2 = this._context.new_var()
      const ee2 = this._context.new_var()
      if (isNaN(bb2.get(0))) bb2.set(0)
      if (isNaN(ee2.get(0))) ee2.set(0)

      if (aa2 > 0) {
        bb2.set(aa2)
        ee2.set(ee2.get(1))
      } else {
        ee2.set(aa2)
        bb2.set(bb2.get(1))
      }

      const dd2 = this.PineJS.Std.sum(ee2, 3000, this._context) / 1500
      const ff2 = this.PineJS.Std.sum(bb2, 3000, this._context) / 1500

      return [dd2, ff2, aa2, 0]
    },
  },
  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'dd2', type: 'line' },
      { id: 'ff2', type: 'line' },
      { id: 'aa2', type: 'line' },
      { id: 'plot', type: 'line' },
    ],
    defaults: {
      styles: {
        dd2: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ffaa00',
        },
        ff2: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00ff00',
        },
        aa2: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00bbff',
        },
        plot: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ffff00',
        },
      },
      precision: 2,
      inputs: {},
    },
    styles: {
      dd2: {
        title: 'dd2',
        histogramBase: 0,
        joinPoints: false,
      },
      ff2: {
        title: 'ff2',
        histogramBase: 0,
        joinPoints: false,
      },
      aa2: {
        title: 'aa2',
        histogramBase: 0,
        joinPoints: false,
      },
      plot: {
        title: 'plot',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [],
  },
})
