import { isNaN } from 'lodash'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { store } from '~/pages/heineken_template/_private/store'

export const south_iv = createIndicator({
  displayName: '隱含波動率',
  id: '隱含波動率',
  // enabledOn(symbol, data, channel) {
  //   return (
  //     (data?.type === channel.tw_futures && symbol.includes('-')) ||
  //     (symbol.includes('TX') && symbol.length == 10)
  //   )
  // },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#iv'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      context.select_sym(1)
      const symbol = store.charting.symbol
      const sessionType =
        symbol === 'TX-1'
          ? SessionType.TAIFEX
          : symbol.includes('-')
          ? SessionType.TAIFEX_AM
          : SessionType.TWSE
      const iv = this.PineJS.Std.volume(context) // ATM
      const ivHigh = this._context.new_var()
      const ivLow = this._context.new_var()

      ivHigh.get(200)
      ivLow.get(200)
      if (isNaN(ivHigh.get(0))) ivHigh.set(0)
      if (isNaN(ivLow.get(0))) ivLow.set(0)

      if (this.isSessionFirstBar(sessionType)) {
        ivHigh.set(iv)
        ivLow.set(iv)
      } else {
        ivHigh.set(this.maxList([iv, ivHigh.get(1)]))
        ivLow.set(this.minList([iv, ivLow.get(1)]))
      }

      return [iv ?? NaN, ivHigh.get(0), ivLow.get(0)]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',

    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,

    plots: [
      { id: 'plot0', type: 'line' },
      { id: 'plot1', type: 'line' },
      { id: 'plot2', type: 'line' },
    ],
    defaults: {
      styles: {
        plot0: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 50,
          color: '#2b7841',
        },
        plot1: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 50,
          color: '#00ff00',
        },
        plot2: {
          linestyle: 0,
          visible: true,
          linewidth: 3,
          // Plot type is Line
          plottype: 2,
          trackPrice: false,
          transparency: 50,
          color: '#E92410',
        },
      },

      precision: 2,
      inputs: {},
    },
    styles: {
      plot0: {
        title: '隱含波動率',
        histogramBase: 0,
      },
      plot1: {
        title: '隱含波動率-H',
        histogramBase: 0,
      },
      plot2: {
        title: '隱含波動率-L',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
