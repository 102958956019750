import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const south_strongWeakGate = createIndicator({
  displayName: '強弱關價',
  id: '強弱關價',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const symbol = this._context.symbol.ticker
      const sessionType =
        symbol === 'TX-1'
          ? SessionType.TAIFEX
          : symbol.includes('-')
          ? SessionType.TAIFEX_AM
          : SessionType.TWSE
      // ------------------------- Get chart information -------------------------
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray

      const kbarLength = Math.ceil(1440 / this._context.symbol.interval) // 1440 minutes per day, divided by interval n
      high.get(kbarLength)
      low.get(kbarLength)

      const strongWeakGate = this.strongWeakGate(sessionType)

      const strongGate: number = strongWeakGate[0]
      const weakGate: number = strongWeakGate[1]
      return [strongGate, weakGate, this.isSessionFirstBar(sessionType) ? 999999 : NaN]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'strongGate', type: 'line' },
      { id: 'weakGate', type: 'line' },
      { id: 'dailySeparator', type: 'histogram' },
    ],

    defaults: {
      styles: {
        strongGate: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#E92410',
        },
        weakGate: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#2b7841',
        },
        dailySeparator: {
          linestyle: 1,
          visible: true,
          linewidth: 1,
          plottype: 1,
          trackPrice: false,
          transparency: 40,
          color: '#999999',
        },
      },
      precision: 2,
      inputs: {},
    },

    styles: {
      strongGate: {
        title: '強關',
        histogramBase: 0,
        joinPoints: false,
      },
      weakGate: {
        title: '弱關',
        histogramBase: 0,
        joinPoints: false,
      },
      dailySeparator: {
        title: '換日線',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
