import { css } from '@emotion/react'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'

export const big3_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
      ${FuiButton.classes.button.Root} {
        display: flex;
        border-radius: 5px;
        height: 32px;
        font-size: 16px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #aaaaaa;
        border: 1px solid #777777;
        background-color: #313d4a;
        &:hover {
          background-color: #3a4654;
          color: #cccccc;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #526b85;
        transition: 0.5s;
        border: 1px solid #eeeeee;
        &:hover {
          color: #eeeeee;
          background-color: #526b85ee;
          border: 1px solid #eeeeee;
          transition: 0.5s;
        }
      }
    }
  `
  store.charting.darkOverrides = {
    ...store.charting.darkOverrides,
    'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
    'mainSeriesProperties.candleStyle.borderUpColor': '#ff2e26',
    'mainSeriesProperties.candleStyle.downColor': '#00ff00',
    'mainSeriesProperties.candleStyle.upColor': '#ff2e26',
    'mainSeriesProperties.candleStyle.wickDownColor': '#cccccc',
    'mainSeriesProperties.candleStyle.wickUpColor': '#cccccc',
    'mainSeriesProperties.candleStyle.drawBorder': false,
    'paneProperties.topMargin': 10,
    'paneProperties.bottomMargin': 10,
    'timeScale.rightOffset': 5,
    'paneProperties.vertGridProperties.color': '#111111',
    'paneProperties.horzGridProperties.color': '#111111',
    'mainSeriesProperties.showPriceLine': false,
  }
}
