import React, { memo, useEffect } from 'react'
import { useInterval } from 'react-use'
import { useSignalrBuild } from '~/modules/SDK/Signalr/useSignalrBuild'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { authTradeAPI } from '~/modules/SDK/Trade/authTradeAPI'
import { useTradeStore } from '~/modules/SDK/Trade/useTradeStore'
import { useOpbsStrategyLogicState } from '~/modules/virtual-exchange-center/containers/useOpbsStrategyLogic'
import { useVirtualExchangeCenterParametersState } from '~/modules/virtual-exchange-center/containers/useVirtualExchangeCenterParameters'
import VirtualExchangeOpbsProvider from '~/modules/virtual-exchange-center/containers/VirtualExchangeOpbsProvider'

const StrategyCenterProvider = memo<ReactProps>(function StrategyCenterProvider(props) {
  useSignalrBuild({ reconnectCheckInterval: 3000 })
  useSignalrStoreValueOHLC.getState().useValueUpdateInterval(5000)
  const currentStrategyName = useTradeStore(state => state.currentStrategyName)
  const strategyAuth = useTradeStore(state => state.strategyAuth)
  useInterval(() => {
    if (currentStrategyName) {
      authTradeAPI.getTransactions()
      authTradeAPI.getOrders()
      authTradeAPI.getPendingOrders()
      authTradeAPI.getPositions()
    }
  }, 10000)

  useEffect(() => {
    if (strategyAuth) {
      authTradeAPI.getMe()
      authTradeAPI.getAvailableStrategies()
    }
  }, [strategyAuth])

  useEffect(() => {
    if (currentStrategyName) {
      authTradeAPI.getTransactions()
      authTradeAPI.getOrders()
      authTradeAPI.getPendingOrders()
      authTradeAPI.getPositions()
    }
  }, [currentStrategyName])

  return (
    <VirtualExchangeOpbsProvider>
      <useVirtualExchangeCenterParametersState.Provider>
        <useOpbsStrategyLogicState.Provider>{props.children}</useOpbsStrategyLogicState.Provider>
      </useVirtualExchangeCenterParametersState.Provider>
    </VirtualExchangeOpbsProvider>
  )
})

export default StrategyCenterProvider
