import { css } from '@emotion/react'
import { memo, useEffect, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { TransactionSymbolList } from '~/modules/page_modules/cons_modules/SymbolQuote/TransactionSymbolQuote'
import { useIntradayPick } from '~/modules/screener/useDatePick'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import {
  useVirtualTransaction,
  VitualExchangeTransaction,
} from '~/modules/virtualExchange/useVirtualExchangeResource'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import { sortSymbolByValue } from '../modules/sortSymbolByValue'
import { lung88988Stock_agentStore } from './lung88988Stock_agentStore'
import { dayTradeIndicators } from './lung88988Stock_initStrategies'

//這是用來將拿到的商品交易紀錄加入成交量做排序,因為原本的商品交易紀錄沒有任何排序
export const useTransactionSymbolSort = (data: VitualExchangeTransaction[]) => {
  const symbollist = data.map(s => s.symbol)

  useEffect(() => {
    signalrStore2.addQuote(symbollist)
    return () => {
      signalrStore2.removeQuote(symbollist)
    }
  }, [symbollist])

  const value = signalrHooks2.useMergeQuotes(data)

  const objectData = () => {
    return value.map(s => {
      const volume = s?.volume
      const symbol = s.symbol
      const bs = s.bs

      return { symbol: symbol, bs: bs, volume: volume }
    })
  }

  const result = objectData().filter(a => a.volume > 10000)

  return result
}

//當日紀錄
export const TransactionSymbolPage = memo(function TransactionSymbolPage() {
  const intraDay = useIntradayPick()

  //交易紀錄--
  const realtimeLongSymbol_ = useVirtualTransaction('lung88988_long', {
    beginDatetime: intraDay,
  })
  const realtimeShortSymbol_ = useVirtualTransaction('lung88988_short', {
    beginDatetime: intraDay,
  })

  //過濾不相關的交易部位--
  const longData = useMemo(() => {
    return realtimeLongSymbol_?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
  }, [realtimeLongSymbol_])

  const shortData = useMemo(() => {
    return realtimeShortSymbol_?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
  }, [realtimeShortSymbol_])

  //所有交易紀錄商品
  const originalData = longData?.concat(shortData ?? []) ?? []

  //交易紀錄的純商品代碼
  const originalSymbols = originalData.map(s => s.symbol)
  const longSymbols = longData?.map(s => s.symbol) ?? ['']
  const shortSymbols = shortData?.map(s => s.symbol) ?? ['']

  //多空交易紀錄商品 多方與空方標的比對並找出重複的
  const allResult = originalSymbols.filter((aa, index) => originalSymbols.indexOf(aa) !== index)

  //多方交易紀錄商品 多方與空方標的比對並去除重複的
  const longResult = longSymbols.filter(function (val) {
    return shortSymbols.indexOf(val) == -1
  })

  //空方交易紀錄商品 多方與空方標的比對並去除重複的
  const shortResult = shortSymbols.filter(function (val) {
    return longSymbols.indexOf(val) == -1
  })

  // //拿報價-成交量 排序

  //新的用法------

  // useEffect(() => {
  //   signalrStore2.addQuote(originalSymbols)
  //   return () => {
  //     signalrStore2.removeQuote(originalSymbols)
  //   }
  // }, [`${originalSymbols}`])

  // const value = signalrHooks2.useQuotes(originalSymbols)

  //舊的用法------

  useEffect(() => {
    useSignalrStore.getState().subscribeAdd([...(originalSymbols || [])], 'ohlc')
    return () => {
      useSignalrStore.getState().subscribeRemove([...(originalSymbols || [])], 'ohlc')
    }
  }, [JSON.stringify(originalSymbols)])

  const value = useSignalrStoreValueOHLC(state => state.useGroupOHLC(originalSymbols))

  const state = useSnapshot(lung88988Stock_agentStore)
  const longType = state.transactionType === 'long'
  const shortType = state.transactionType === 'short'
  const allType = state.transactionType === 'all'

  const resultData = () => {
    if (longType) {
      return longResult
    } else if (shortType) {
      return shortResult
    } else {
      return allResult
    }
  }
  const resultType = () => {
    if (longType) {
      return 'B'
    } else if (shortType) {
      return 'S'
    } else {
      return 'All'
    }
  }

  const resultSortData = sortSymbolByValue(value, resultData(), 'volume')

  return (
    <div
      css={css`
        width: 100%;
        height: calc(100% - 0px);
      `}
    >
      <div
        css={css`
          ${flex.h.allCenter};
          height: 30px;
          gap: 4px;
        `}
      >
        <FuiButton.Display
          variant='long'
          active={longType}
          onClick={event => {
            lung88988Stock_agentStore.transactionType = 'long'
          }}
        >
          多方標的
        </FuiButton.Display>
        <FuiButton.Display
          active={allType}
          onClick={event => {
            lung88988Stock_agentStore.transactionType = 'all'
          }}
        >
          全部標的
        </FuiButton.Display>
        <FuiButton.Display
          variant='short'
          active={shortType}
          onClick={event => {
            lung88988Stock_agentStore.transactionType = 'short'
          }}
        >
          多方標的
        </FuiButton.Display>
      </div>
      <div
        css={css`
          ${flex.h.allCenter};
          height: calc(100% - 32px);
        `}
      >
        <TransactionSymbolList
          data={resultSortData}
          bs={resultType()}
          chart={store.charting}
          indicators={dayTradeIndicators}
          handleClick={() => store.charting.changeInterval('5')}
        />
      </div>
    </div>
  )
})
