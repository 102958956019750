import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'
import { amy_daytrade } from '~/trades/indicators/amy/amy_dayTrade'
import { amy_diffPower } from '~/trades/indicators/amy/amy_diffPower'
import { amy_seasonLine } from '~/trades/indicators/amy/amy_seasonLine'
import { amy_signal } from '~/trades/indicators/amy/amy_signal'
import { amy_volume } from '~/trades/indicators/amy/amy_volume'

export const amyStock_strategiesGroup = {
  /** 主要策略集 */
  main: [
    {
      displayName: '趨勢波段',
      indicators: [amy_signal, amy_diffPower, amy_volume],
      symbol: '2330',
      interval: 'D',
      panesRatio: 66,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const amyStock_initStrategies = () => {
  store.charting.indicatorsPreloaded = [
    ...store.charting.indicatorsPreloaded,
    ...[amy_signal, amy_diffPower, amy_volume],
  ]
  store.charting.initStrategy({
    configs: [...amyStock_strategiesGroup.main],
  })
}
