import { SessionType } from '~/modules/SDK/Chart2/SessionType'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { calculateIndicatorValue } from './calculateIndicatorValue'
import { perf2Store } from '~/modules/SDK/Perf/perf2Store'
import dayAPI from '~/utils/dayAPI'

export const god_signal = createIndicator({
  id: 'god-signal',
  displayName: '策略訊號',
  constructorScope: {
    init(context, inputCallback) {
      perf2Store.reset()
      return
    },
    main(context, inputCallback) {
      let triggerTradeAction = false
      const symbol = context.symbol.info?.ticker
      const resolution = context.symbol.period // 1, 3, 5, 15, 30, 60, 1D, 1W

      const MAX_ENTRIES_TODAY = 3
      const STOP_LOSS = this._input(0)
      const TAKE_PROFIT = this._input(1)

      //#region Prepare data
      const position = context.new_var()
      const entryPrice = context.new_var()
      const exitPrice = context.new_var()
      const entriesToday = context.new_var()

      const close = this.ohlc.closeArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const volume = context.new_var(this.PineJS.Std.volume(context))
      const itime = this.PineJS.Std.time(this._context)

      position.get(1)
      entriesToday.get(100)
      entryPrice.get(100)
      exitPrice.get(100)

      close.get(100)
      high.get(100)
      low.get(100)
      volume.get(100)

      if (dayAPI(itime).hour() === 8 && dayAPI(itime).minute() === 45) {
        entriesToday.set(0)
      }

      //#endregion

      //#region Calc indicators
      const ma = this.average(close, 12)
      const volumeThreshold = this.average(volume, 6) + this.average(volume, 12)
      const parameters = {
        biasLength: 12,
        percentRLength: 13,
        rsiLength: 6,
        kdLength: 9,
      }

      const indicatorFast = context.new_var(
        calculateIndicatorValue(
          context.symbol.period as '1W' | '1D' | '5' | '1',
          this.bias(parameters.biasLength),
          this.percentR(parameters.percentRLength),
          this.rsi(close, parameters.rsiLength),
          this.stochastic(parameters.kdLength, 3, 3)[0],
        ),
      )
      const indicatorSlow = context.new_var(
        calculateIndicatorValue(
          context.symbol.period as '1W' | '1D' | '5' | '1',
          this.bias(parameters.biasLength * 5),
          this.percentR(parameters.percentRLength * 5),
          this.rsi(close, parameters.rsiLength * 5),
          this.stochastic(parameters.kdLength * 5, 3, 3)[0],
        ),
      )
      //#endregion

      //#region Conditions

      const timeCondition = dayAPI(itime).hour() < 13
      const longEntry =
        timeCondition &&
        indicatorFast.get(0) < 15 &&
        indicatorSlow.get(0) < 15 &&
        volume.get(0) > volumeThreshold &&
        entriesToday.get(0) < MAX_ENTRIES_TODAY

      const shortEntry =
        timeCondition &&
        indicatorFast.get(0) > 85 &&
        indicatorSlow.get(0) > 85 &&
        volume.get(0) > volumeThreshold &&
        entriesToday.get(0) < MAX_ENTRIES_TODAY
      //#endregion

      let entryLongFlag = NaN
      let exitLongFlag = NaN
      let entryShortFlag = NaN
      let exitShortFlag = NaN

      if (longEntry) {
        position.set(1)
      }

      if (shortEntry) {
        position.set(-1)
      }

      // ---------------------------------------- Entry ----------------------------------------
      if (position.get(0) === 1 && position.get(1) !== 1) {
        entryLongFlag = 1
        entryShortFlag = NaN
        entriesToday.set(entriesToday.get(0) + 1)
        entryPrice.set(close.get(0))
        triggerTradeAction = true
      }

      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryShortFlag = 1
        entryLongFlag = NaN
        entryPrice.set(close.get(0))
        entriesToday.set(entriesToday.get(0) + 1)
        triggerTradeAction = true
      }

      // ---------------------------------------- Exit ----------------------------------------
      if (position.get(0) === 1 && position.get(1) === 1) {
        // Take Profit
        if (high.get(0) > entryPrice.get(1) + TAKE_PROFIT) {
          exitLongFlag = 1
          position.set(0)
          exitPrice.set(entryPrice.get(1) + TAKE_PROFIT)
          triggerTradeAction = true
        }
        // stoploss
        if (low.get(0) < entryPrice.get(1) - STOP_LOSS) {
          exitLongFlag = 1
          position.set(0)
          exitPrice.set(entryPrice.get(1) - STOP_LOSS)
          triggerTradeAction = true
        }
        //13:30
        if (dayAPI(itime).hour() === 13 && dayAPI(itime).minute() === 30) {
          exitLongFlag = 1
          position.set(0)
          exitPrice.set(close.get(0))
          triggerTradeAction = true
        }
      }

      if (position.get(0) === -1 && position.get(1) === -1) {
        //固定停利
        if (low.get(0) < entryPrice.get(1) - TAKE_PROFIT) {
          exitShortFlag = 1
          position.set(0)
          exitPrice.set(entryPrice.get(1) - TAKE_PROFIT)
          triggerTradeAction = true
        }

        // stop loss
        if (high.get(0) > entryPrice.get(1) + STOP_LOSS) {
          exitShortFlag = 1
          position.set(0)
          exitPrice.set(entryPrice.get(1) + STOP_LOSS)
          triggerTradeAction = true
        }
        //13:30
        if (dayAPI(itime).hour() === 13 && dayAPI(itime).minute() === 30) {
          exitShortFlag = 1
          position.set(0)
          exitPrice.set(close.get(0))
          triggerTradeAction = true
        }
      }

      //isBarChanging-------------------------------------------------------------------
      const localTimeVar = this._context.new_var()
      const timeDeltaMs = 500
      const isBarChanging =
        isNaN(localTimeVar.get(1)) ||
        Math.abs(new Date().getTime() - localTimeVar.get(1)) < timeDeltaMs
      localTimeVar.set(new Date().getTime())

      if (triggerTradeAction && isBarChanging) {
        perf2Store.addTrade({
          symbol: symbol || '',
          interval: resolution,
          openAt: this.PineJS.Std.time(this._context),
          bar0: {
            position: position.get(0),
            priceEntry: entryPrice.get(0),
            priceExit: exitPrice.get(0),
          },
          bar1: {
            position: position.get(1),
            priceEntry: entryPrice.get(1),
          },
        })
      }

      return [entryLongFlag, exitLongFlag, entryShortFlag, exitShortFlag]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    inputs: [
      {
        id: 'stoploss',
        name: '停損點數',
        defval: 30,
        type: 'integer',
        min: 1,
        max: 10000,
        step: 1,
      },
      {
        id: 'takeProfit',
        name: '停利點數',
        defval: 60,
        type: 'integer',
        min: 1,
        max: 10000,
        step: 1,
      },
    ],
    plots: [
      { id: 'entryFlagLong', type: 'chars' },
      { id: 'exitFlagLong', type: 'chars' },
      { id: 'entryFlagShort', type: 'chars' },
      { id: 'exitFlagShort', type: 'chars' },
    ],
    defaults: {
      precision: 2,
      inputs: {
        stoploss: 30,
        takeProfit: 60,
      },
      styles: {
        entryFlagLong: { color: '#FF0000', textColor: '#FF0000', transparency: 20, visible: true },
        exitFlagLong: { color: '#FF0000', textColor: '#FF0000', transparency: 20, visible: true },
        entryFlagShort: { color: '#5FFA6D', textColor: '#5FFA6D', transparency: 20, visible: true },
        exitFlagShort: { color: '#5FFA6D', textColor: '#5FFA6D', transparency: 20, visible: true },
      },
    },
    styles: {
      entryFlagLong: {
        title: '進場點(多)',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單進場',
      },
      exitFlagLong: {
        title: '出場點(多)',
        isHidden: false,
        location: 'AboveBar',
        char: '*',
        size: 'small',
        text: '多單出場',
      },
      entryFlagShort: {
        title: '進場點(空)',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單進場',
      },
      exitFlagShort: {
        title: '出場點(空)',
        isHidden: false,
        location: 'BelowBar',
        char: '*',
        size: 'small',
        text: '空單出場',
      },
    },
  },
})
