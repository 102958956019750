import { css } from '@emotion/react'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import FuiButton from '../heineken_template/components/FuiButton'

export const opkevin_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      .Drawer2 {
        width: 140px;
        background: linear-gradient(85deg, #121212, #121212);
      }
      .MuiModal-backdrop {
        background: linear-gradient(90deg, #12121211, #121212);
      }

      ${FuiButton.classes.button.Root} {
        border-radius: 5px;
        height: 26px;
        width: 112px;
      }

      ${FuiButton.classes.button.default.Default} {
        color: #cccccc;
        background-color: #1e1e1e;
        border: 1px solid #555555;
        &:hover {
          background-color: #2f3136;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }

      ${FuiButton.classes.button.default.Active} {
        color: #eeeeee;
        background-color: #354a93;
        border: 1px solid #eeeeee;
        transition: 0.5s;
        &:hover {
          background-color: #496ac0;
          border: 1px solid #bbbbbb;
          color: #eeeeee;
          transition: 0.5s;
        }
      }
    }
  `
}
