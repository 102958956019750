import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { fontWeight600 } from '~/css/font'

const styleds = {
  //#region Side Pane 1
  SideBar1: styled.div`
    ${fill_vertical_cross_center};
    background-color: #16161e;
    gap: 4px;
    padding: 4px;
  `,
  IntradayTrendChart: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    height: 192px;
    background-color: #515151;
    border-radius: 4px;
    padding: 4px;
  `,
  StrategyTitle: styled.div`
    ${fill_vertical_all_center}
    width: 100%;
    height: 32px;
    background: linear-gradient(90deg, #ff0000, #b02d2d);
    color: #ffffff;
    border-radius: 2px;
    font-size: 20px;
    ${fontWeight600};
  `,
  ScreenerTitle: styled.div<{ variant: 'long' | 'short' }>`
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
    ${fontWeight600};
    ${options => {
      const buyCssset =
        options.variant === 'long' &&
        css`
          background-color: #c62a31;
        `

      const shortCssset =
        options.variant === 'short' &&
        css`
          background-color: #00ae00;
        `
      return css([buyCssset, shortCssset])
    }}
  `,
  ComponeyLogo: styled.img`
    width: 50%;
    height: 64px;
    background-color: #d0dce6;
    border-radius: 4px;
  `,
  //#endregion

  //#region Side Pane 2

  SideBar2: styled.div`
    ${fill_vertical_cross_center} background-color: #16161e;
    gap: 4px;
    padding: 4px;
  `,
  Logo: styled.div`
    width: 100%;
    height: 176px;
    //background-color: #252525;
    background-image: url('jerry597/logo-god.png');
    background-size: 40%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
  `,
  TradingTipsTitle: styled.div`
    ${fill_vertical_all_center}
    height: 32px;
    background-color: #cd6a37;
    border-radius: 2px;
    font-size: 22px;
    ${fontWeight600};
  `,
  TradingTipsContent: styled.div`
    ${fill_vertical_all_center}
    height: calc(100% - 112px);
    font-size: 16px;
    background: linear-gradient(90deg, #252525, #333333);
    border-radius: 8px;
  `,

  //#new
  StrategyArea: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    height: 72px;
    background-color: #555555;
    border-radius: 4px;
    padding: 4px;
    gap: 2px;
  `,
  SymbolLsitArea: styled.div`
    ${fill_vertical_cross_center};
    width: 100%;
    height: calc(100% - 272px);
    background-color: #555555;
    border-radius: 4px;
    padding: 4px;
    gap: 2px;
  `,
  AreaTitle: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    font-size: 20px;
    background-color: #333333;
    color: #ffffff;
    border-radius: 2px;
    gap: 8px;
    ${fontWeight600};
  `,
  SymbolList: styled.div`
    height: calc(50% - 48px);
    width: 100%;
  `,
  StrategyGroup: styled.div`
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 5px;
  `,
}

export default styleds
