import { css } from '@emotion/react'
import { memo } from 'react'
import { fill } from '~/modules/AppLayout/FlexGridCss'
import { SymbolSimpleTrendChartCard } from '../eurex/_private/eurex_SidePaneMenu'

const symbols = ['2303', 'YM-1', '2303', '2014', '6116']
export const Investment_SidePaneMenu = memo<ReactProps>(function Investment_SidePaneMenu() {
  return (
    <div
      css={css`
        ${fill}
        background-color: #efefef;
      `}
    >
      商品報價列
      {symbols.map(s => {
        return (
          <SymbolSimpleTrendChartCard
            symbol={s}
            key={s}
          />
        )
      })}
    </div>
  )
})
