import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { store } from '~/pages/heineken_template/_private/store'

export const asia71178_strategiesGroup = {
  main: [
    {
      displayName: '策略',
      indicators: [],
      symbol: 'TX-1',
      interval: 'D',
      panesRatio: 60,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const asia71178_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...[]]

  store.charting.initStrategy({
    configs: [...asia71178_strategiesGroup.main],
  })
}
