import { MantineProvider } from '@mantine/core'
import { memo } from 'react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { useSnapshot } from 'valtio'
import { store } from '../heineken_template/_private/store'
import IntradayTrendChart from '~/modules/trendChart'
import FuiButton from '../heineken_template/components/FuiButton'
import styleds from './styleds'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import { jerry597_stockIndicators, jerry597_strategyGroup } from './jerry597_initStrategies'
import { GiRadarSweep } from 'react-icons/gi'
import { MdAddchart } from 'react-icons/md'
import { useBig3ConsecutiveResource } from '~/modules/screener/containers/useStockScreenerResource'
import { staticStore } from '../heineken_template/_private/staticStore'

export const Jerry597_SidePane1 = memo<ReactProps>(function Jerry597_SidePane1() {
  const colorTheme = useThemeStore(state => state.theme)
  const charting = useSnapshot(store.charting)

  const lastTradeDate = useSnapshot(staticStore).tradedDate.day0

  const longSymbol = useBig3ConsecutiveResource({
    date: lastTradeDate.format('YYYY-MM-DD'),
    days: 3,
    action: 'buy',
    institution: 'foreigner',
    volume_lte: 10000,
    volume_gte: 500,
  })

  const shortSymbol = useBig3ConsecutiveResource({
    date: lastTradeDate.format('YYYY-MM-DD'),
    days: 5,
    action: 'sell',
    institution: 'foreigner',
    volume_lte: 10000,
    volume_gte: 500,
  })

  console.log('Strategy DisplayName =>', store.charting.strategySelected.displayName)
  return (
    <MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar1>
        <styleds.IntradayTrendChart>
          <styleds.AreaTitle>
            <TitleSymbolQuote.Default symbol={charting.symbol} />
          </styleds.AreaTitle>
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={14}
            ticksHeight={16}
            transparency={0.4}
            priceTicksSize={14}
            priceTicksMargin={-15}
          />
        </styleds.IntradayTrendChart>

        <styleds.StrategyArea>
          <styleds.AreaTitle>
            <MdAddchart
              size={'30px'}
              fill={'#ff5500'}
            />
            金旺指標
          </styleds.AreaTitle>
          <styleds.StrategyGroup>
            {jerry597_strategyGroup.future.map(strategyConfig => {
              const isActive = charting.strategySelected.displayName === strategyConfig.displayName
              return (
                <FuiButton.Display
                  key={strategyConfig.displayName}
                  active={isActive}
                  onClick={event => {
                    store.charting.setStrategy(strategyConfig)
                    store.charting.changeSymbol(strategyConfig.symbol ?? '')
                    store.charting.updateFromStrategyState()
                  }}
                >
                  {strategyConfig.displayName}
                </FuiButton.Display>
              )
            })}
          </styleds.StrategyGroup>
        </styleds.StrategyArea>
        <styleds.SymbolLsitArea>
          <styleds.AreaTitle>
            <GiRadarSweep
              size={'30px'}
              fill={'#ff5500'}
            />
            金旺選股
          </styleds.AreaTitle>
          <styleds.ScreenerTitle variant='long'>金旺多方選股</styleds.ScreenerTitle>
          <styleds.SymbolList>
            <SimpleQuoteListInteractWithChart
              data={longSymbol.data?.map(s => s.symbol) ?? []}
              chart={store.charting}
              indicators={jerry597_stockIndicators}
              handleClick={() => console.log('TODO: Handle clicked')} // FIXME:
            />
          </styleds.SymbolList>
          <styleds.ScreenerTitle variant='short'>金旺空方選股</styleds.ScreenerTitle>
          <styleds.SymbolList>
            <SimpleQuoteListInteractWithChart
              data={shortSymbol.data?.map(s => s.symbol) ?? []} // FIXME: dynamic for production env
              chart={store.charting}
              indicators={jerry597_stockIndicators}
              handleClick={() => console.log('TODO: Handle clicked')} // FIXME:
            />
          </styleds.SymbolList>
        </styleds.SymbolLsitArea>
      </styleds.SideBar1>
    </MantineProvider>
  )
})
