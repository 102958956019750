import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const gorich55688_stockSignal = createIndicator({
  id: 'gorich55688-stockSignal',
  displayName: '股票日K買訊',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      if (this._context.symbol.ticker.includes('-')) return [0]

      const close = this.ohlc.closeArray
      const volume = this._context.new_var(this.PineJS.Std.volume(this._context))

      const position = this._context.new_var()
      if (isNaN(position.get(0))) position.set(0)

      const ma5 = this._context.new_var(this.average(close, 5))
      const ma10 = this._context.new_var(this.average(close, 10))
      const volumeMA10 = this._context.new_var(this.average(volume, 10))
      const kd = this.stochastic(9, 3, 3)
      const k = kd[0]
      const d = kd[1]

      close.get(100)
      volume.get(100)
      ma5.get(10)
      ma10.get(10)
      volumeMA10.get(10)

      const entrySetup =
        volume.get(0) > volumeMA10.get(0) &&
        ma5.get(0) > ma10.get(0) &&
        k > d &&
        d > 50 &&
        close.get(0) > ma5.get(4) &&
        close.get(1) < ma5.get(4)

      let entryFlag = 0
      entryFlag = entrySetup ? 1 : 0
      return [entryFlag]
    },
  },
  metainfo: {
    _metainfoVersion: 42,
    is_price_study: true,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [{ id: 'entryFlag', type: 'chars' }],
    defaults: {
      precision: 0,
      inputs: {},
      styles: {
        entryFlag: { color: '#ff0000', textColor: '#ff0000', transparency: 20, visible: true },
      },
    },
    styles: {
      entryFlag: {
        title: '進場點(多)',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單進場',
      },
    },
    inputs: [],
  },
})
