import { css } from '@emotion/react'
import { sortBy } from 'lodash'
import { memo } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useSnapshot } from 'valtio'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'

import { getIndicatorAngular } from '~/modules/strength-state/getIndicatorAngular'
import IntradayTrendChart from '~/modules/trendChart'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { store } from '~/pages/heineken_template/_private/store'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { big3_store } from './big3_store'
import { CirculProgressbarStyled, styleds } from './styleds'

const Title = {
  original: '08:45 ~ 13:44',
  new: '08:45 ~ 13:45 & 20:00 ~ 22:59',
  all: '08:45 ~ 13:45 & 15:00 ~ 04:59(T+1)',
}

export const Big3_SidePane1 = memo<ReactProps>(function Big3_SidePane1() {
  const state = useSnapshot(big3_store)

  const mainMarketValue = getIndicatorAngular(state.mainMarketValue) * 100
  const retailMarketValue = getIndicatorAngular(state.retailMarketValue) * 100

  const chipValue = getIndicatorAngular(state.chip) * 100
  const opofValue = getIndicatorAngular(state.opof) * 100

  const charting = useSnapshot(store.charting)
  const symbolNumber = charting.symbol

  return (
    <styleds.SideBar>
      <TitleSymbolQuote.Default symbol={symbolNumber} />
      <div
        css={css`
          width: 100%;
          height: 164px;
          padding: 4px;
        `}
      >
        <IntradayTrendChart
          symbol={symbolNumber}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </div>
      <styleds.SideBarTitle>市場力道</styleds.SideBarTitle>
      <CirculProgressbar value={chipValue}>市場力道</CirculProgressbar>
      <CirculProgressbar value={opofValue}>領先力道</CirculProgressbar>
      <styleds.SideBarTitle>短線關鍵價</styleds.SideBarTitle>
      <CrucialPrice />
      <TimeRangeSelecter />
      {state.timeRange === 'original' && Title.original}
      {state.timeRange === 'new' && Title.new}
      {state.timeRange === 'all' && Title.all}
    </styleds.SideBar>
  )
})

export const TimeRangeSelecter = memo<ReactProps>(function TimeRangeSelecter() {
  const state = useSnapshot(big3_store)

  return (
    <styleds.TimeRangeContainer>
      <FuiButton.Display
        onClick={() => {
          big3_store.timeRange = 'all'
          store.charting.updateFromState()
        }}
        active={state.timeRange === 'all'}
      >
        全部時段
      </FuiButton.Display>
      <FuiButton.Display
        onClick={() => {
          big3_store.timeRange = 'new'
          store.charting.updateFromState()
        }}
        active={state.timeRange === 'new'}
      >
        重點時段
      </FuiButton.Display>
      <FuiButton.Display
        onClick={() => {
          big3_store.timeRange = 'original'
          store.charting.updateFromState()
        }}
        active={state.timeRange === 'original'}
      >
        日盤時段
      </FuiButton.Display>
    </styleds.TimeRangeContainer>
  )
})

export const CrucialPrice = memo<ReactProps>(function CrucialPrice() {
  const state = useSnapshot(big3_store)
  const crucialPrice = sortBy(state.crucialPrice).reverse()
  const close = state.symbolClose
  return (
    <styleds.CrucialPriceContainer>
      {crucialPrice.map((value, index) => {
        const title = close > value ? '支撐' : close < value ? '壓力' : '現價'
        const color = close > value ? '#eb483f' : close < value ? '#6fe747' : '#ffffff'
        return (
          <div
            key={index}
            css={css`
              color: ${color};
            `}
          >
            關鍵{title}: {value ?? 0}
          </div>
        )
      })}
    </styleds.CrucialPriceContainer>
  )
})

export const CirculProgressbar = memo<ReactProps<{ value: number }>>(function CirculProgressbar(
  props,
) {
  const color = props.value > 0 ? '#ff3333' : props.value < 0 ? '#33ff33' : '#00000000'
  return (
    <CirculProgressbarStyled.Container>
      <CirculProgressbarStyled.ProgressbarContent>
        <CircularProgressbar
          value={Math.abs(props.value)}
          text={`${props.value.toFixed(2)}%`}
          circleRatio={0.75}
          strokeWidth={14}
          styles={buildStyles({
            rotation: 1 / 2 + 1 / 8,
            strokeLinecap: 'butt',
            textColor: '#eeeeee',
            textSize: 14,
            pathColor: color,
            trailColor: '#556677',
          })}
        />
      </CirculProgressbarStyled.ProgressbarContent>
      <CirculProgressbarStyled.NameTitle>{props.children}</CirculProgressbarStyled.NameTitle>
    </CirculProgressbarStyled.Container>
  )
})
