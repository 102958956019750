import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { god_indicatorColoredFast } from '~/trades/indicators/god/god_indicatorColoredFast'
import { god_indicatorColoredSlow } from '~/trades/indicators/god/god_indicatorColoredSlow'
import { god_movAvg } from '~/trades/indicators/god/god_movAvg'
import { god_signal } from '~/trades/indicators/god/god_signal'
import { god_volume } from '~/trades/indicators/god/god_volume'
import { store } from '../heineken_template/_private/store'

const DEFAULT_SYMBOL = 'TXAM-1'

export const indicatorGroup = [god_signal, god_movAvg, god_volume]

export const god_strategyGroup = {
  main: [
    {
      displayName: 'Main strategy group',
      indicators: [...indicatorGroup],
      symbol: DEFAULT_SYMBOL,
      interval: '5',
      panesRatio: 60,
      //calcFrom: dayAPI().subtract(7, 'day'),
    },
  ] as ChartTypes.StrategyConfig[],
}

export const god_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...indicatorGroup]

  store.charting.initStrategy({
    configs: [...god_strategyGroup.main],
  })

  store.charting.changeSymbol(DEFAULT_SYMBOL)
}
