import { css } from '@emotion/react'
import { memo } from 'react'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'

export const South_Footer = memo<ReactProps>(function Kabujuku_Footer(props) {
  return (
    <div
      css={css`
        ${fill_horizontal_all_center}
        width: 100%;
        height: 100%;
        background-color: #191342;
        color: #cccccc;
        font-size: 14px;
      `}
    >
      群益期貨股份有限公司 許可字號:111年金管會期總字第010號
    </div>
  )
})
