import { css } from '@emotion/react'
import { Dayjs } from 'dayjs'
import React, { memo, useMemo } from 'react'
import {
  ResponsiveContainer,
  YAxis,
  XAxis,
  AreaChart,
  Area,
} from 'recharts-new'
import { LiteralUnion } from 'type-fest'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import dayAPI from '~/utils/dayAPI'

const SimpleTrendChart = memo<
  React.PropsWithChildren<{
    endOnSpecDate?: Dayjs
    symbol: Signalr.SymbolString
    /** 時間單位, 可以顯示近期的 `週、日、小時` 圖表區間 */
    interval?: 'day' | 'hour' | 'week'
    /** 要顯示的數量, 即是 `5週|5天|5小時` 根據所選的時間單位 */
    timeCount?: number
    /** 拿到報價週期預設 `'1D'` */
    dataInterval?: LiteralUnion<'1D' | '1H', string>
    /** 實際要採用的數量 */
    count?: number
    /** 外部給予圖表顏色 */
    fillColor?: string
  }>
>(function SimpleTrendChart(props) {
  const recentDays = props.timeCount || 365
  /** 後端最新交易資料 國內的交易日期 */

  const baseDate = dayAPI()
  const endOnSpecDate = props.endOnSpecDate || baseDate

  /** 使用的單位 週、天、小時 */
  const timeUnit = props.interval || 'day'

  const isWeekend6 = endOnSpecDate.day() === 6
  const isWeekend0 = endOnSpecDate.day() === 0
  const isWeekend1 = endOnSpecDate.day() === 1 && endOnSpecDate.day() < 6

  const dataDate = () => {
    //基礎日期使用了當地時間,為了解決在假日撈不到資料的問題,在週末時間分別設置基礎日期
    //週六 將時間設置: 週六上午5:00
    //週日 將時間設置: 週六上午5:00
    //週一 將時間設置: 週六上午5:00
    if (isWeekend6) {
      return baseDate.set('date', endOnSpecDate.date()).set('hour', 5).set('minute', 0)
    } else if (isWeekend0) {
      return baseDate
        .set('date', endOnSpecDate.subtract(1, 'day').date())
        .set('hour', 5)
        .set('minute', 0)
    } else if (isWeekend1) {
      return baseDate
        .set('date', endOnSpecDate.subtract(2, 'day').date())
        .set('hour', 5)
        .set('minute', 0)
    }
    return endOnSpecDate
  }

  const range: [number, number] = [
    dataDate().add(-recentDays, timeUnit).unix(),
    dataDate().endOf('day').unix(),
  ]

  console.log(
    { recentDays: recentDays, timeUnit: timeUnit },
    {
      start: dataDate().add(-recentDays, timeUnit).format('YYYY/MM/DD-HH:mm'),
      end: dataDate().endOf('day').format('YYYY/MM/DD-HH:mm'),
    },
  )

  const { data: rawData } = useSymbolHistoryResource({
    symbol: props.symbol,
    fromTo: range,
    resolution: props.dataInterval ?? '1D',
  })

  const data = useMemo(() => {
    return rawData
      ?.map(datum => ({
        close: datum.close,
      }))
      .slice(props.count ? Math.max((rawData?.length ?? 0) - props.count - 1, 0) : 0)
  }, [rawData, props.count])

  if (!data || (data && data.length === 0))
    return (
      <div
        css={css`
          ${fill_horizontal_all_center}
        `}
      >
        ...尚未載入
      </div>
    )

  /** 以下 ID 是處理 重複使用圖表顏色無法指定問題 */
  const splitColorID = ('splitColor' + props.symbol).toString()
  const strokeColorID = ('strokeColor' + props.symbol).toString()
  const fillID = 'url(#' + splitColorID + ')'
  const strokeID = 'url(#' + strokeColorID + ')'

  const start = rawData?.map(s => s.open)[0]
  const end = rawData?.map(s => s.close)[rawData.length - 1]
  const rise = (start ?? 0) <= (end ?? 0)
  const color = props.fillColor ?? (rise ? '#dd2222' : '#22bb22')

  const yRange: [number, number] = [
    Math.min(...(data?.map(datum => datum.close) || [])),
    Math.min(...(data?.map(datum => datum.close) || [])),
  ]

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <AreaChart
        data={data}
        margin={{
          top: 1,
          right: 1,
          left: 1,
          bottom: 1,
        }}
      >
        <YAxis
          domain={yRange}
          hide={true}
        />
        <XAxis
          hide={true}
          interval={10}
        />

        <defs>
          <linearGradient
            id={splitColorID}
            x1='0'
            y1='0'
            x2='0'
            y2='1'
          >
            <stop
              offset={1 / 5}
              stopColor={color}
              stopOpacity={1}
            />
            <stop
              offset={5 / 5}
              stopColor={color}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type='linear'
          dot={false}
          dataKey='close'
          stroke={color}
          fillOpacity={0.3}
          fill={fillID}
          isAnimationActive={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
})

export default SimpleTrendChart
