import { MantineProvider } from '@mantine/core'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { store } from '../heineken_template/_private/store'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { css } from '@emotion/react'
import styleds from './styleds'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple'
import IntradayTrendChart from '~/modules/trendChart'
import { Dayjs } from 'dayjs'
import { SignalWallId } from '~/modules/signal-wall/SignalWallId'
import useSWR from 'swr-0-5-6'
import urlcat from 'urlcat'
import { apirc } from '~/configs/apirc'
import dayAPI from '~/utils/dayAPI'
import {
  useIntraday,
  useLatestTradeDateSize,
} from '~/modules/screener/containers/useStockScreenerResource'
import { gorich55688_store } from './gorich55688_store'
import FuiButton from '../heineken_template/components/FuiButton'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'
import { gorich55688_patternStore } from './gorich55688_patternStore'
import { staticStore } from '../heineken_template/_private/staticStore'
import { gorich55688_strategyGroup } from './gorich55688_initStrategies'

export const Gorich55688_SidePane1 = memo<ReactProps>(function Gorich55688_SidePane1() {
  const colorTheme = useThemeStore(state => state.theme)
  const charting = useSnapshot(store.charting)

  //#region Screener results
  const lastTradeDatePick = useLatestTradeDateSize({ size: 5 })
  const lastTradeDate = lastTradeDatePick?.[0]
  const beforeLastTradeDate = lastTradeDatePick?.[1]

  //const nowTime = dayAPI().hour() >= 9 && dayAPI().hour() <= 15 && dayAPI().minute() <= 30

  const intraday = useSnapshot(staticStore).tradedDate.intraday
  const useBeforeLastTradeDate =
    dayAPI(intraday).format('YYYY-MM-DD') === lastTradeDate ? beforeLastTradeDate : lastTradeDate

  const startDate = dayAPI(useBeforeLastTradeDate)
  const today = dayAPI()

  const screenerResult1 = useSignalWall({
    signal_id: SignalWallId.gorich55688_強勢整理股,
    from: startDate,
    to: today,
  }).data?.map(f => f.symbol)

  const screenerResult2 = useSignalWall({
    signal_id: SignalWallId.gorich55688_籌碼優勢股,
    from: startDate,
    to: today,
  }).data?.map(f => f.symbol)

  //#endregion
  const patterns = useSnapshot(gorich55688_patternStore)
  return (
    <MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar1>
        <styleds.IndexState>
          <div css={fill_horizontal_all_center}>控盤十六式</div>
          <div css={fill_horizontal_all_center}>{patterns.pattern}</div>
        </styleds.IndexState>
        <TitleSymbolQuote.Default symbol={charting.symbol} />

        <styleds.IntradayTrendChart>
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={12}
            ticksHeight={16}
            transparency={0.4}
            priceTicksSize={12}
            priceTicksMargin={-15}
          />
        </styleds.IntradayTrendChart>
        <div
          css={css`
            ${fill_horizontal_all_center};
            height: 32px;
            width: 100%;
            gap: 4px;
          `}
        >
          {gorich55688_strategyGroup.main.map(config => {
            const active = charting.strategySelected.displayName === config.displayName

            return (
              <FuiButton.Display
                key={config.displayName}
                active={active}
                onClick={() => {
                  store.charting.setStrategy(config)
                  store.charting.updateFromStrategyState()
                }}
              >
                {config.displayName}
              </FuiButton.Display>
            )
          })}
        </div>
        <styleds.ScreenerResultTitle>選股結果</styleds.ScreenerResultTitle>
        <div
          css={css`
            ${fill_horizontal_all_center};
            height: 30px;
            width: 100%;
            gap: 4px;
          `}
        >
          <gorich55688_store.screenerTabs.WithButton to='強勢整理股'>
            <FuiButton.Display>強勢整理股</FuiButton.Display>
          </gorich55688_store.screenerTabs.WithButton>
          <gorich55688_store.screenerTabs.WithButton to='籌碼優勢股'>
            <FuiButton.Display>籌碼優勢股</FuiButton.Display>
          </gorich55688_store.screenerTabs.WithButton>
        </div>

        <styleds.ScreenerResultContent>
          <gorich55688_store.screenerTabs.WithContent for='強勢整理股'>
            <SimpleQuoteListInteractWithChart
              data={screenerResult1 ?? []}
              chart={store.charting}
            />
          </gorich55688_store.screenerTabs.WithContent>

          <gorich55688_store.screenerTabs.WithContent for='籌碼優勢股'>
            <SimpleQuoteListInteractWithChart
              data={screenerResult2 ?? []}
              chart={store.charting}
            />
          </gorich55688_store.screenerTabs.WithContent>
        </styleds.ScreenerResultContent>
      </styleds.SideBar1>
    </MantineProvider>
  )
})

//https://signal-wall.run.futures-ai.com/search/signal-wall?from=1667404800&signal_id=ED_m168168_01
//https://signal-wall.run.futures-ai.com/search/signal-wall?from=1667404800&to=1667545200&page=1&size=100&signal_id=RT_futuresai_02
//測試XQ選股訊號用的api
//from: 前一個交易日的13:30開始查詢
//to: 當日
export const useSignalWall = (options: {
  signal_id: SignalWallId
  from: Dayjs
  to?: Dayjs
  //陣列數量最大100
  size?: number
  //若陣列超過100 則換下頁=2
  page?: number
}) => {
  const res = useSWR(
    urlcat(`deps`, options),
    async () => {
      const data = await apirc.signalWall.get({
        signal_id: options.signal_id,
        from: options.from,
        to: options.to ?? dayAPI(),
        size: options.size ?? 50,
        page: options.page ?? 1,
      })

      return data
    },
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  )

  return {
    data: res.data,
  }
}
