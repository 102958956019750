import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useState } from 'react'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { scrollbar2Css } from '~/css/scrollbarCss'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
  flex,
} from '~/modules/AppLayout/FlexGridCss'
import SymbolCardHoldingProfitBody from '~/modules/investment-consultant/symbolList/body/SymbolCardHoldingProfitBody'
import SymbolRankingContent from '~/modules/investment-consultant/symbolList/body/SymbolCardRankingBody'
import SymbolRankingHorizontalBar from '~/modules/investment-consultant/symbolRankingChart/SymbolRankingHorizontalBar'
import SymbolBrickBody from '~/modules/investment-consultant/symbolList/body/SymbolCardBrickBody'
import SymbolCanrdTrendChart from '~/modules/investment-consultant/symbolList/body/SymbolCardTrendChart'
import SymbolOverviewChart from '~/modules/investment-consultant/symbolList/overView/SymbolOverviewChart'
import SymbolSimpleCard from '~/modules/investment-consultant/symbolList/body/SymbolCardSimpleBody'
import SimpleQuoteBodyItem from '~/modules/symbolQuote/simple/SimpleQuoteBodyItem'
import IntradayTrendChart from '~/modules/trendChart'
import { PriceGauges } from '../eurex/_private/component/Gauges'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { useSnapshot } from 'valtio'
import { investmentStore } from './investment_TopBar'
import SymbolTransactionBody from '~/modules/investment-consultant/symbolList/body/SymbolCardTransactionBody'
import TemperatureGauges from '~/modules/investment-consultant/gaugus/TemperatureGauges'
import LinearGauges from '~/modules/investment-consultant/gaugus/LinearGauges'

const symbols = ['2303', 'YM-1', '6143', '2014', '6116', '3443', '3034']
const demoSymbol = ['2330', '2609']
export const Investment_SidePane1 = memo<ReactProps>(function Investment_SidePane1() {
  const theme = useThemeStore(s => s.theme)

  const currentSymbol = useSnapshot(investmentStore).currentSymbol

  return (
    <classes.container className={theme}>
      <ComponentGroup1 />
      <ComponentGroup2 />
      <ComponentGroup3 />
      <ComponentGroup4 />
    </classes.container>
  )
})

export const ComponentGroup1 = memo<ReactProps>(function ComponentGroup1() {
  return (
    <classes.pane column='1'>
      走勢概覽
      <classes.item
        css={css`
          width: 100%;
          height: 232px;
        `}
      >
        <SymbolOverviewChart.Display symbol={['3693', '2609', 'TX-1']} />
      </classes.item>
      走勢圖
      <classes.item
        css={css`
          width: 100%;
          height: 172px;
        `}
      >
        <IntradayTrendChart
          symbol={'TX-1'}
          ticksSize={14}
          ticksHeight={20}
          priceTicksMargin={2.5}
          priceTicksSize={14}
        />
      </classes.item>
    </classes.pane>
  )
})

export const ComponentGroup2 = memo<ReactProps>(function ComponentGroup2() {
  return (
    <classes.pane column='2'>
      <classes.item>
        標題報價(一般)
        <TitleSymbolQuote.Default symbol={'2330'} />
      </classes.item>
      <classes.item>
        簡易報價(成交量)
        <TitleSymbolQuote.DefaulVolume symbol={'2330'} />
      </classes.item>
      <classes.item>
        簡易報價(預估量)
        <TitleSymbolQuote.EstimatedVol
          symbol={'2330'}
          days={5}
          unit={'張'}
        />
      </classes.item>
      <classes.item>
        簡易報價(開高低收)
        <TitleSymbolQuote.Ohlc symbol={'2330'} />
      </classes.item>
    </classes.pane>
  )
})

export const ComponentGroup3 = memo<ReactProps>(function ComponentGroup3() {
  const currentSymbol = useSnapshot(investmentStore).currentSymbol
  return (
    <classes.pane column='3'>
      <classes.item>
        走勢報價
        <SymbolCanrdTrendChart.Display
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
        />
      </classes.item>
      <classes.item>
        一般報價
        <SimpleQuoteBodyItem.Default
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
        />
      </classes.item>
      <classes.item>
        交易紀錄報價
        <SymbolTransactionBody.Display
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
          bs='B'
        />
      </classes.item>
      <classes.item>
        持倉報價
        <SymbolCardHoldingProfitBody.DisplayStyled1
          symbol={demoSymbol[0]}
          averagePrice={380}
          currentSymbol={currentSymbol}
          qty={1}
          pnl={1}
          marketPrice={400}
          lastModifiedDatetime='2023/12/31'
        />
      </classes.item>
      <classes.item>
        持倉報價2
        <SimpleQuoteBodyItem.Holding
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
          entryPrice={380}
          equity={1}
          profit={(530 - 380) * 1000}
          date='2023/12/31'
        />
      </classes.item>
      <classes.item>
        排行報價
        <SymbolRankingContent.standard.Display
          symbol={demoSymbol[0]}
          countArray={[5, 10, 20, 60]}
          currentSymbol={currentSymbol}
        />
      </classes.item>
      <classes.item>
        排行報價
        <SymbolRankingContent.simple.Display
          symbolNumber={demoSymbol[0]}
          countArray={[5, 10, 20, 60]}
          useChart={store.charting}
        />
      </classes.item>

      <classes.item css={flex.h.default}>
        <SymbolBrickBody.Display
          symbol={demoSymbol[0]}
          currentSymbol={currentSymbol}
        />
        <SymbolBrickBody.Display
          symbol={demoSymbol[1]}
          currentSymbol={currentSymbol}
        />
      </classes.item>

      <classes.item>
        簡單報價
        <SymbolSimpleCard.Display symbol={demoSymbol[0]} />
      </classes.item>
    </classes.pane>
  )
})
export const ComponentGroup4 = memo<ReactProps>(function ComponentGroup4() {
  return (
    <classes.pane column='4'>
      <classes.item>
        <TemperatureGauges.Display currentValue={30} />
      </classes.item>
      <classes.item>
        <LinearGauges.Display currentValue={0.7} />
      </classes.item>

      <classes.item css={classCss.chartHeight}>
        <SymbolRankingHorizontalBar.Display
          symbols={symbols}
          type='changePercent'
          nameLabelWidth={30}
        />
      </classes.item>
      <classes.item css={classCss.chartHeight}>
        <SymbolRankingHorizontalBar.Display
          symbols={symbols}
          type='volume'
          nameLabelWidth={30}
        />
      </classes.item>
    </classes.pane>
  )
})

const classes = {
  container: styled.div`
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-columns: repeat(4, 336px);
    gap: 18px;
    padding: 8px;
    height: 100%;
    &.dark {
      background-color: #252525;
      color: #ffffff;
    }
    &.light {
      background-color: #ffffff;
      color: #252525;
    }
  `,
  pane: styled.div<{ column: string }>`
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    grid-column: ${option => option.column};
    gap: 8px;
  `,
  item: styled.div`
    ${fill_vertical_all_center};
    height: auto;
    padding: 4px;
    border-radius: 8px;
    gap: 8px;
    border: 1px solid #cccccc;
  `,
}

const classCss = {
  baseWidth: css`
    width: 336px;
  `,
  baseHeight: css`
    height: 40px;
  `,
  baseFill: css`
    height: 40px;
    width: 336px;
  `,
  chartHeight: css`
    min-height: 200px;
  `,
}
