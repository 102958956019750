import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { proxy, useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { useStkfutDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import dayAPI from '~/utils/dayAPI'
import { StockRatioGraph } from '../daddy960_goodway/component/StockRatioGraph'
import { StockScoreRankSlider } from '../daddy960_goodway/component/StockScoreRankSlider'
import { StockScoreSort } from '../daddy960_goodway/component/StockScoreSort'
import { StockScoreTableHeader } from '../daddy960_goodway/component/StockScoreTable'
import { StockVolumeListTable } from '../daddy960_goodway/component/StockVolumeListTable'
import { store } from '../heineken_template/_private/store'
import { groupStore, watchListConfig } from './daddy960_stocknerve_SidePane2'

export const dayStore = proxy({
  load: false as boolean,
})

export const Daddy960_stocknerve_SidePane1 = memo<ReactProps>(
  function Daddy960_stocknerve_SidePane1() {
    //const charting = useSnapshot(store.charting)
    //const symbolNumber = charting.symbol

    const loadState = useSnapshot(dayStore).load

    useEffect(() => {
      if (loadState === true) {
        //store.charting.calcFrom = dayAPI().subtract(3, 'day')
        store.charting.setVisibleRange(dayAPI().subtract(3, 'day'))
      }
    }, [loadState])

    return (
      <styleds.container>
        <div
          onClick={() => {
            store.charting.setVisibleRange(dayAPI().subtract(5, 'day'))
          }}
        >
          顯示近期5天
        </div>
        <styleds.dashboardHeader>
          <div>氣氛靈敏度</div>
          <div>分數排序</div>
        </styleds.dashboardHeader>
        <styleds.dashboard>
          <StockScoreRankSlider />
          <StockScoreSort />
        </styleds.dashboard>
        <styleds.content height={'32px'}>
          <StockRatioGraph />
        </styleds.content>
        <WatchListGroup />
        <styleds.content height={'32px'}>
          <StockScoreTableHeader />
        </styleds.content>
        <styleds.content height={'calc(100% - 188px)'}>
          <StockVolumeList />
        </styleds.content>
      </styleds.container>
    )
  },
)

/** 觀察清單上的群組選擇器 (預設)(群組1)... */
export const WatchListGroup = memo<ReactProps>(function WatchListGroup() {
  const groupState = useSnapshot(groupStore)
  const isDefaultSelect = groupState.defaultData === true
  return (
    <styleds.watchListGroupContainer>
      <styleds.watchListGroupButton
        selected={isDefaultSelect}
        onClick={() => (groupStore.defaultData = true)}
      >
        全部
      </styleds.watchListGroupButton>
      {watchListConfig.map((item, index) => {
        const isSelect = item.groupName === groupState.watchListData && !isDefaultSelect
        return (
          <styleds.watchListGroupButton
            key={index}
            selected={isSelect}
            onClick={() => {
              groupStore.watchListData = item.groupName
              groupStore.defaultData = false
            }}
          >
            {item.displayText}
          </styleds.watchListGroupButton>
        )
      })}
    </styleds.watchListGroupContainer>
  )
})

/** 顯示預設股期商品 或是自選商品(過濾非股期商品) */
export const StockVolumeList = memo<ReactProps>(function StockVolumeList() {
  const groupState = useSnapshot(groupStore).watchListData
  const { state } = useSymbolWatchList(groupState)
  const symbolListArray = state.symbolListArray

  /** 自選股的股期商品 */
  const filteredData: string[] = symbolListArray?.filter((item: string) => {
    if (item.length !== 5) {
      // 長度不符合條件
      return false
    }
    const prefix: string = item.substr(0, 2)
    const suffix: string = item.substr(2)
    if (!/^[A-Z]{2}$/.test(prefix) || suffix !== 'F-1') {
      // 前面兩子字串必須為A-Z且後面字串需要為F-1
      return false
    }
    return true
  })

  /** 股期成交量前30名 */
  const stkRankData =
    useStkfutDailyRankResource(
      {
        sort_by: 'volume',
        order_by: 'desc',
        volume_gte: 1,
        volume_lte: 10000000,
        amount_gte: 0,
        amount_lte: 0,
        limit: 50,
      },
      1,
    ).data?.map(s => s.symbol) ?? []

  const resultData = useSnapshot(groupStore).defaultData === true ? stkRankData : filteredData

  return (
    <StockVolumeListTable
      changeSymbol={true}
      data={resultData}
    />
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    font-size: 14px;
    user-select: none;
  `,
  content: styled.div<{ height: string }>`
    width: 100%;
    height: ${props => props.height};
  `,
  dashboardHeader: styled.div`
    ${fill_horizontal_all_center};
    grid-template-columns: 70% 30%;
    height: 32px;
    display: grid;
    gap: 4px;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    & > div {
      ${fill_horizontal_all_center};
      background-color: #252525;
      border-radius: 5px;
    }
  `,
  dashboard: styled.div`
    ${fill_horizontal_all_center};
    height: 40px;
    display: grid;
    gap: 4px;
    grid-template-columns: calc(70% - 2px) calc(30% - 2px);
    & > div {
      background-color: #252525;
      border-radius: 5px;
    }
  `,
  watchListGroupContainer: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
    gap: 4px;
  `,
  watchListGroupButton: styled.div<{ selected: boolean }>`
    ${fill_horizontal_all_center};
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
    background-color: ${props => (props.selected ? '#4759cc' : '#252525')};
    &:hover {
      background-color: ${props => (props.selected ? '#4759cccc' : '#353535')};
    }
  `,
}
