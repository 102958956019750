import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import { god_store } from '~/pages/god/god_store'

export const god_volume = createIndicator({
  id: 'god-volume',
  displayName: '成交量',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const volume = this._context.new_var(this.PineJS.Std.volume(this._context))
      volume.get(100)

      const fastLength = this._input(0)
      const slowLength = this._input(1)
      const fastMA = this.average(volume, fastLength)
      const slowMA = this.average(volume, slowLength)

      const isVolumeIncreasing = volume.get(0) > fastMA + slowMA
      const isVolumeDecreasing = volume.get(0) < fastMA + slowMA

      god_store.volumeState = isVolumeIncreasing ? '量增' : isVolumeDecreasing ? '量縮' : '正常'

      return [
        !isVolumeIncreasing && !isVolumeDecreasing ? volume.get(0) : NaN,
        isVolumeIncreasing ? volume.get(0) : NaN,
        isVolumeDecreasing ? volume.get(0) : NaN,
        slowMA + fastMA,
      ]
    },
  },
  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,
    inputs: [
      {
        id: 'fastLength',
        name: 'Fast Length',
        defval: 6,
        type: 'integer',
        min: 1,
        max: 100,
        step: 1,
      },
      {
        id: 'slowLength',
        name: 'Slow Length',
        defval: 12,
        type: 'integer',
        min: 1,
        max: 100,
        step: 1,
      },
    ],

    plots: [
      { id: 'volume', type: 'line' },
      { id: 'increasing', type: 'line' },
      { id: 'decreasing', type: 'line' },
      { id: 'avg', type: 'line' },
    ],
    styles: {
      volume: {
        title: '成交量',
        histogramBase: 0,
        joinPoints: false,
      },
      increasing: {
        title: '量增',
        histogramBase: 0,
        joinPoints: false,
      },
      decreasing: {
        title: '量縮',
        histogramBase: 0,
        joinPoints: false,
      },
      avg: {
        title: '均量(6+12)',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    defaults: {
      styles: {
        volume: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#666699',
        },
        increasing: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ffff00',
        },
        decreasing: {
          linestyle: 0,
          linewidth: 2,
          plottype: 5,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#3399ff',
        },
        avg: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#F1B3E2',
        },
      },
      precision: 2,
      inputs: { fastLength: 6, slowLength: 12 },
    },
  },
})
