import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const gorich55688_stockSignal2 = createIndicator({
  id: 'gorich55688-stockSignal2',
  displayName: '股票日K買訊2',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      if (this._context.symbol.ticker.includes('-')) return [0]

      const close = this.ohlc.closeArray
      const open = this.ohlc.openArray
      const volume = this._context.new_var(this.PineJS.Std.volume(this._context))

      const position = this._context.new_var()

      const ma5 = this._context.new_var(this.average(close, 5))
      const ma10 = this._context.new_var(this.average(close, 10))
      const ma60 = this._context.new_var(this.average(close, 60))
      const volumeMA10 = this._context.new_var(this.average(volume, 10))
      const kd = this.stochastic(9, 3, 3)
      const k = kd[0]
      const d = kd[1]

      close.get(100)
      open.get(100)
      volume.get(100)
      ma5.get(10)
      ma10.get(10)
      volumeMA10.get(10)
      position.get(2)

      const maxClose = Math.max(close.get(1), close.get(2), close.get(3), close.get(4))
      const maxOpen = Math.max(open.get(1), open.get(2), open.get(3), open.get(4))

      const condition_volume = volume.get(0) > volumeMA10.get(0)
      const condition_kd = k > d
      const condition_d = d > 50
      const condition_ma = ma5.get(0) > ma10.get(0)
      const condition_ma_slpoe = ma5.get(0) > ma5.get(1) && ma10.get(0) > ma10.get(1)
      const condition_crossover_maxprice = close > Math.max(maxClose, maxOpen)

      let entryFlag = 0

      const entrySetup =
        condition_volume &&
        condition_kd &&
        condition_d &&
        condition_ma &&
        condition_ma_slpoe &&
        condition_crossover_maxprice

      if (entrySetup) {
        position.set(1)
      }
      if (position.get(0) === 1 && position.get(1) === 0) {
        entryFlag = 1
      }

      if (position.get(0) === 1 && position.get(1) === 1) {
        if (close < ma5.get(0) && close < ma10.get(0) && close < ma60 && !condition_ma_slpoe) {
          position.set(0)
        }
      }

      return [entryFlag]
    },
  },
  metainfo: {
    _metainfoVersion: 42,
    is_price_study: true,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [{ id: 'entryFlag', type: 'chars' }],
    defaults: {
      precision: 0,
      inputs: {},
      styles: {
        entryFlag: { color: '#ff0000', textColor: '#ff0000', transparency: 20, visible: true },
      },
    },
    styles: {
      entryFlag: {
        title: '進場點(多)',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多單進場',
      },
    },
    inputs: [],
  },
})
