import { MantineProvider } from '@mantine/core'
import { memo, useEffect, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'
import { store } from '../heineken_template/_private/store'
import { useThemeStore } from '~/components/theme/useThemeStore'
import styleds from './styled'
import { Loader } from '@mantine/core'
import IntradayTrendChart from '~/modules/trendChart'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { perf2Store } from '~/modules/SDK/Perf/perf2Store'
import { sortBy } from 'lodash'
import map from 'lodash/map'
import { css } from '@emotion/react'
import { scrollbar2Css } from '~/css/scrollbarCss'
import styled from '@emotion/styled'
import {
  fill_horizontal_all_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { staticStore } from '../heineken_template/_private/staticStore'
import dayAPI from '~/utils/dayAPI'
import CircularProgress from '@mui/material/CircularProgress'

export const God_SidePane1 = memo<ReactProps>(function God_SidePane1() {
  const colorTheme = useThemeStore(state => state.theme)
  const charting = useSnapshot(store.charting)

  // const indicatorState = useSnapshot(god_store).indicatorState
  // const volumeState = useSnapshot(god_store).volumeState

  // console.log(`
  // IndicatorState: ${indicatorState}
  // VolumeState: ${volumeState}
  // `)
  //#endregion

  return (
    <MantineProvider theme={{ colorScheme: colorTheme }}>
      <styleds.SideBar1>
        <TitleSymbolQuote.Default symbol={charting.symbol} />

        <styleds.TrendChartContent>
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={12}
            ticksHeight={16}
            transparency={0.4}
            priceTicksSize={12}
            priceTicksMargin={-15}
          />
        </styleds.TrendChartContent>

        <div
          css={css`
            width: 100%;
            height: calc(100% - 200px);
          `}
        >
          <PerfTable />
        </div>
      </styleds.SideBar1>
    </MantineProvider>
  )
})

type MonthlyProfits = { [month: string]: number }

const accumulateProfitByMonth = (data: { datetime: string; profit: number }[]) => {
  const monthsProfit = {} as MonthlyProfits
  const monthTradeCount = {} as MonthlyProfits

  for (let i = 0; i < data.length; i++) {
    const { datetime, profit } = data[i]
    const month = datetime.slice(0, 7)

    if (!monthsProfit[month]) {
      monthsProfit[month] = 0
      monthTradeCount[month] = 0
    }
    monthsProfit[month] += profit
    monthTradeCount[month]++
  }

  return { monthsProfit: monthsProfit, monthTradeCount: monthTradeCount }
}

const LoadingStatus = memo<ReactProps>(function LoadingStatus() {
  return (
    <perfStyleds.emptyContent>
      <div>資料載入中</div>
      <CircularProgress
        size={24}
        disableShrink
      />
    </perfStyleds.emptyContent>
  )
})

/** 交易詳細資料與結論 */
export const PerfTable = memo<ReactProps>(function PerfTable() {
  const intraday = useSnapshot(staticStore).tradedDate.intraday
  const intraDate = dayAPI(intraday).format('MM-DD')

  perf2Store.useConnect(1000)
  const trades = useSnapshot(perf2Store)

  const summariesTableData = useMemo(
    () => [...map(trades.status?.profitDetailDataArray || {})],
    [trades.status?.profitDetailDataArray],
  )

  /** 依照日期排列並保留平倉資訊 */
  const data = sortBy(summariesTableData, datum => datum.datetime).filter(
    datum => datum.profit !== 0,
  )
  /** 判斷完整載入data */
  const loadingState = data.length > 10

  /** 近期5筆交易資料 */
  const lastData = data.slice(-5).filter(datum => datum.datetime.slice(5, 10) === intraDate)

  /** 月獲利與月交易次數 object */
  const tradeInfo = accumulateProfitByMonth(data)

  /** 轉陣列 */
  const tradeInfoArray = Object.entries(tradeInfo.monthTradeCount).map(([month, count]) => ({
    month,
    count,
    profit: tradeInfo.monthsProfit[month],
  }))

  return (
    <perfStyleds.container>
      {/* 歷史交易明細 */}
      <perfStyleds.title backgroundFill='#722c2c'>歷史交易明細</perfStyleds.title>
      <perfStyleds.positionHeader>
        <perfStyleds.value>交易日</perfStyleds.value>
        <perfStyleds.value>部位</perfStyleds.value>
        <perfStyleds.value>開倉價</perfStyleds.value>
        <perfStyleds.value>平倉價</perfStyleds.value>
        <perfStyleds.value>損益</perfStyleds.value>
      </perfStyleds.positionHeader>
      <perfStyleds.historyContent>
        {loadingState &&
          data.reverse().map((datum, index) => {
            const positionDisplay = datum.qty > 0 ? '做多' : datum.qty < 0 ? '做空' : ''
            return (
              <perfStyleds.positionBody key={index}>
                <perfStyleds.value>
                  {datum.datetime.slice(5, 10).replace('-', '/')}
                </perfStyleds.value>
                <perfStyleds.value>
                  <perfStyleds.position value={datum.qty}>{positionDisplay}</perfStyleds.position>
                </perfStyleds.value>
                <perfStyleds.value>{datum.entryPrice}</perfStyleds.value>
                <perfStyleds.value>{datum.exitPrice}</perfStyleds.value>
                <perfStyleds.profit value={datum.profit}>{datum.profit}</perfStyleds.profit>
              </perfStyleds.positionBody>
            )
          })}
        {!loadingState && <LoadingStatus />}
      </perfStyleds.historyContent>
      {/* 近月累積績效 */}
      <perfStyleds.title backgroundFill='#722c2c'>近月累積績效</perfStyleds.title>
      <perfStyleds.monthProfitHeader>
        <perfStyleds.value>交易月份</perfStyleds.value>
        <perfStyleds.value>交易筆數</perfStyleds.value>
        <perfStyleds.value>總獲利</perfStyleds.value>
      </perfStyleds.monthProfitHeader>
      <perfStyleds.monthInfoContent>
        {loadingState &&
          tradeInfoArray.reverse().map((datum, index) => (
            <perfStyleds.monthProfitBody key={index}>
              <div>{datum.month.replace('-', '/')}</div>
              <div>{datum.count}</div>
              <perfStyleds.profit value={datum.profit}>{datum.profit}</perfStyleds.profit>
            </perfStyleds.monthProfitBody>
          ))}
        {!loadingState && <LoadingStatus />}
      </perfStyleds.monthInfoContent>
      {/* 當日交易明細 */}
      <perfStyleds.title backgroundFill='#1976d2'>
        {intraDate.replace('-', '/')}當日交易明細
      </perfStyleds.title>
      <perfStyleds.positionHeader>
        <perfStyleds.value>平倉時間</perfStyleds.value>
        <perfStyleds.value>部位</perfStyleds.value>
        <perfStyleds.value>開倉價</perfStyleds.value>
        <perfStyleds.value>平倉價</perfStyleds.value>
        <perfStyleds.value>損益</perfStyleds.value>
      </perfStyleds.positionHeader>
      <perfStyleds.intradayContent>
        {loadingState &&
          lastData.reverse().map((datum, index) => {
            const positionDisplay = datum.qty > 0 ? '做多' : datum.qty < 0 ? '做空' : ''
            return (
              <perfStyleds.positionBody key={index}>
                <perfStyleds.value>{datum.datetime.slice(10)}</perfStyleds.value>
                <perfStyleds.value>
                  <perfStyleds.position value={datum.qty}>{positionDisplay}</perfStyleds.position>
                </perfStyleds.value>
                <perfStyleds.value>{datum.entryPrice}</perfStyleds.value>
                <perfStyleds.value>{datum.exitPrice}</perfStyleds.value>
                <perfStyleds.profit value={datum.profit}>{datum.profit}</perfStyleds.profit>
              </perfStyleds.positionBody>
            )
          })}
        {!loadingState && <LoadingStatus />}
      </perfStyleds.intradayContent>
    </perfStyleds.container>
  )
})

const perfStyleds = {
  title: styled.div<{ backgroundFill: string }>`
    ${fill_horizontal_all_center};
    height: 32px;
    background-color: ${props => props.backgroundFill};
  `,
  container: styled.div`
    ${fill_vertical_cross_center};
  `,
  historyContent: styled.div`
    ${scrollbar2Css};
    gap: 4px;
    height: calc(100% - 360px);
  `,
  intradayContent: styled.div`
    ${scrollbar2Css};
    gap: 4px;
    height: 104px;
  `,
  monthInfoContent: styled.div`
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: 120px;
  `,
  emptyContent: styled.div`
    ${fill_vertical_all_center}
  `,
  positionHeader: styled.div`
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    height: 30px;
    width: 100%;
    background-color: #252525;
    font-size: 14px;
  `,
  positionBody: styled.div`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    height: 30px;
    width: 100%;
    background-color: #151515;
    font-size: 14px;
    &:hover {
      cursor: pointer;
      background-color: #313131;
    }
  `,
  value: styled.div`
    ${fill_horizontal_all_center};
    height: 30px;
  `,
  position: styled.div<{ value: number }>`
    ${fill_horizontal_all_center};
    height: 26px;
    width: 36px;
    color: ${props => (props.value > 0 ? '#ee2222' : '#00cc00')};
    border-radius: 4px;
  `,
  profit: styled.div<{ value: number }>`
    ${fill_horizontal_all_center};
    height: 30px;
    color: ${props => (props.value > 0 ? '#ee2222' : '#00cc00')};
  `,
  monthProfitHeader: styled.div`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 30% 30% 40%;
    height: 30px;
    background-color: #252525;
    font-size: 14px;
  `,
  monthProfitBody: styled.div`
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 30% 30% 40%;
    height: 30px;
    & > * {
      ${fill_horizontal_all_center};
    }
    &:hover {
      cursor: pointer;
      background-color: #313131;
    }
  `,
}
