import { css } from '@emotion/react'
import { PositionInfomationClasses } from '~/modules/investment-consultant/positionValueForm/PositonInfotion'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import { store } from '~/pages/heineken_template/_private/store'
import WatchSymbolToggleButtonSingle from '../heineken_template/_col/col_WatchSymbolToggleSingleButton'

export const daddy960_stocknerve_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__body {
      ${WatchSymbolToggleButtonSingle.classes.Root} {
        height: 32px;
      }
      ${(WatchSymbolToggleButtonSingle.buttonClasses.notJoined = '#425ad3')}
    }
  `
}
