import { memo } from 'react'
import { ManualSignal } from '~/modules/investment-consultant/signalNotify/ManualSignal'
import styleds from './styleds'

export const Jerry597_SidePane2 = memo<ReactProps>(function Jerry597_SidePane2() {
  return (
    <styleds.SideBar2>
      <styleds.ComponeyLogo src='jerry597/logo.png' />
      <styleds.TradingTipsTitle>金旺操作提示</styleds.TradingTipsTitle>
      <styleds.TradingTipsContent>
        <ManualSignal
          agent={'jerry597'}
          limit={10}
        />
      </styleds.TradingTipsContent>
    </styleds.SideBar2>
  )
})
