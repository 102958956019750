import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'

export const Asia71178_SidePane = memo<ReactProps>(function Asia71178_SidePane() {
  return <styleds.container></styleds.container>
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
  `,
}
