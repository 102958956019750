import { filter, head, last, map, reduce, size, sortBy, sum } from 'lodash'
import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'
import { debugAPI } from '~/modules/SDK/debug/debugAPI'
import { getBigPointValue } from '~/modules/SDK/indicator/contextUtils/getBigPointValue'
import { getPositionProfit } from '~/modules/SDK/indicator/contextUtils/getPositionProfit'
import { perfGetMaxDrawDown } from '~/modules/SDK/Perf/perfGetMaxDrawDown'
import { PerfTypes } from '~/modules/SDK/Perf/PerfTypes'
import { usePerf2Connect } from '~/modules/SDK/Perf/usePerf2Connect'
import dayAPI from '~/utils/dayAPI'

/** `sessionStorage` */
enum SessionKey {
  INTERVAL = 'CHART_INTERVAL',
  LAST_MODIFIED = 'CHART_LAST_MODIFIED',
  SYMBOL = 'CHART_SYMBOL',
}

const initialState: PerfTypes.Summary = {
  endDate: '',
  maxDrawdown: 0,
  maxLossTrade: 0,
  maxWinTrade: 0,
  profitDataArray: [],
  profitDetailDataArray: [],
  profitFactor: 0,
  profitLossPerTrade: 0,
  profitNet: 0,
  profitSumDataArray: [],
  profitTotalTrade: 0,
  profitWinPerTrade: 0,
  riskRatio: 0,
  startDate: '',
  trades: {},
  tradesSize: 0,
  winRate: 0,
  winTradeCount: 0,
  lossTradeCount: 0,
  tradeRatio: 0,
}

/**
 * @example
 *   // NextPage
 *
 *   const SomeOneNextPage: NextPage = props => {
 *     const perf2State = useSnapshot(perf2Store)
 *
 *     // 設定它定時從 indicator 刷新 state 的速率
 *     perf2Store.useConnect(1000)
 *
 *     return <div></div>
 *   }
 *
 *   export default SomeOneNextPage
 */
export const perf2Store = proxy({
  useConnect: usePerf2Connect,
  /** 策略績效狀態 */
  status: { ...initialState },
  /** 績效初始資金 */
  initialAmount: 500000,
  /** Chart/indicator 最後更新時間，是更新的嗎？ */
  hasNewLastModified() {
    return perf2Store.localStorage.getLastModified() > perf2Store.private.lastModified
  },
  /**
   * 重置回最初 0 的形狀
   *
   * - 例如可以放在 `createIndicator.init()` 裡面，使它 init() 時重置
   */
  reset() {
    debugAPI.perf.log(`[重置] ${perf2Store.reset.name}()`)
    perf2Store.status = { ...initialState }
    perf2Store.private.lastModified = 0
  },
  /**
   * 新增一筆交易記錄
   *
   * - 例如可以在 `createIndicator.main()` 找合適的邏輯加入一筆交易積效
   */
  addTrade(state: {
    symbol: string
    interval: string
    /** 交易時間 */
    openAt: number
    /** 當前K棒持倉狀況 */
    bar0: {
      /** `1` 一單多倉; `-1` 一單空倉 */
      position: number
      priceEntry?: number
      priceExit?: number
    }
    /** 前一根K棒持倉狀況 */
    bar1: {
      /** `1` 一單多倉; `-1` 一單空倉 */
      position: number
      priceEntry?: number
      priceExit?: number
    }
  }) {
    const params = {
      bigPointValue: getBigPointValue(state.symbol),
      bar0: { ...state.bar0 },
      bar1: { ...state.bar1 },
    }

    const { profit } = getPositionProfit(params)

    const datetime = dayAPI(state.openAt)

    const newDealTrade: PerfTypes.Deal = {
      datetime: datetime,
      priceAt: state.bar0.priceEntry ?? 0,
      profit,
      qty: Number(state.bar0.position),
    }

    // 透過刷新 object ref 來更新 Store 本身 state 觸發 proxy 的更新
    perf2Store.status.trades = {
      ...perf2Store.status.trades,
      [datetime.format('YYYY-MM-DD HH:mm')]: newDealTrade,
    }

    // 刷新 react 端修改時間，防止過份 re-render
    perf2Store.localStorage.setLastModified()

    debugAPI.perf.log(
      `[新增] ${perf2Store.addTrade.name}() [${datetime.format('MM-DD HH:mm')}] (${
        params.bar0.position
      }) 進:${Number(params.bar0.priceEntry)} 出:${Number(params.bar0.priceExit)}`,
      {
        ...params,
        ...newDealTrade,
      },
    )
  },
  /**
   * 計算最大回撤、獲利因子、交易虧損次數等，需要被計算出來的數值，並更新本身 state
   *
   * @example
   *   // 在某個組件定期計算
   *
   *   useIntervalNow(() => {
   *     perf2Store.updateTradesStatus()
   *   }, 1000)
   */
  updateStats() {
    /** 照時間序排列 */
    const trades = sortBy(
      map(perf2Store.status.trades, item => ({ ...item })),
      (left, right) => dayAPI(left.datetime).toDate().getTime(),
    )
    /** 進場 */
    const entryPriceArray = map(trades, datum => datum.priceAt)
    /** 出場 */
    const exitPriceArray = map(
      trades,
      (datum, index) =>
        Number(datum.priceAt) + (datum.profit / 200) * (index > 0 ? trades[index - 1].qty : 0),
    )
    /** 單筆交易損益 */
    const profitArray = map(trades, datum => datum.profit)
    /** 單筆交易日期 */
    const dateArray = map(trades, datum => dayAPI(datum.datetime).format('YYYY-MM-DD HH:mm'))
    /** 單筆交易 */
    const qtyArray = map(trades, datum => datum.qty)
    /** 損益累加 */
    let sumProfit = 0
    const profitSumArray = map(trades, datum => (sumProfit += datum.profit))

    let sumProfitData: {
      datetime: string
      profit: number
      qty: number
    }[] = []

    let profitData: {
      datetime: string
      profit: number
      qty: number
    }[] = []

    let profitDetailData: {
      datetime: string
      profit: number
      entryPrice: number | string
      exitPrice: number | string
      qty: number
    }[] = []

    /** 累加損益陣列 */
    sumProfitData = reduce(
      dateArray,
      (prev, value, index) => {
        prev[index] = {
          datetime: dateArray[index],
          profit: profitSumArray[index],
          qty: qtyArray[index] || 0,
        }
        return prev
      },
      [] as typeof sumProfitData,
    )

    /** 單筆損益陣列 */
    profitData = reduce(
      dateArray,
      (prev, value, index) => {
        prev[index] = {
          datetime: dateArray[index],
          profit: profitArray[index],
          qty: qtyArray[index] || 0,
        }
        return prev
      },
      [] as typeof profitData,
    )

    /** 單筆損益明細陣列資料 */
    profitDetailData = reduce(
      dateArray,
      (prev, value, index) => {
        prev[index] = {
          datetime: dateArray[index],
          profit: profitArray[index],
          entryPrice: entryPriceArray[index],
          exitPrice: exitPriceArray[index],
          qty: qtyArray[index - 1] || 0, //開倉部位
        }
        return prev
      },
      [] as typeof profitDetailData,
    )

    /** 策略起始日 */
    const startDate = dayAPI(head(dateArray)).format('YYYY/MM/DD')
    /** 策略最新日 */
    const endDate = dayAPI(last(dateArray)).format('YYYY/MM/DD')
    /** 交易次數 */
    const tradesSize = filter(trades, datum => datum.profit !== 0).length
    /** 交易獲利次數 */
    const profitTrades = filter(trades, datum => datum.profit > 0)
    /** 交易虧損次數 */
    const profitLossTrades = filter(trades, datum => datum.profit < 0)
    /** 交易獲利加總 */
    const profitWinTotal = sum(map(trades, datum => (datum.profit > 0 ? datum.profit : 0)))
    /** 交易虧損加總 */
    const profitLossTotal = sum(map(trades, datum => (datum.profit < 0 ? datum.profit : 0)))
    /** 交易淨損益 */
    const profitNet = profitWinTotal + profitLossTotal
    /** 獲利因子 */
    const pf = 1 + profitNet / (perf2Store.initialAmount + profitNet)
    /** 平均每筆獲利 */
    const profitWinPerTrade = (profitWinTotal / profitTrades.length) | 0
    /** 平均每筆虧損 */
    const profitLossPerTrade = (profitLossTotal / profitLossTrades.length) | 0
    /** 單次交易最大獲利 */
    const maxWin = Math.max(...(profitArray ?? 0))
    // const maxWin = max(profitArray) ?? 0
    /** 單次交易最大虧損 */
    const maxLoss = Math.min(...(profitArray ?? 0))
    // const maxLoss = min(profitArray) ?? 0
    /** 風險報酬比 */
    const riskRatio = Math.abs(profitNet / (maxLoss || profitNet))
    /** 賺賠比 */
    const tradeRatio = profitWinPerTrade / -profitLossPerTrade
    /** 最大回撤 */
    const maxDrawdown = Math.abs(perfGetMaxDrawDown(profitArray))
    /** 累加損益陣列資料 for reChart */
    const profitSumDataArray = sumProfitData
    /** 單筆損益陣列資料 for reChart */
    const profitDataArray = profitData
    /** 單筆損益明細陣列資料 */
    const profitDetailDataArray = profitDetailData

    perf2Store.status.profitFactor = pf
    perf2Store.status.profitNet = profitNet
    perf2Store.status.profitWinPerTrade = profitWinPerTrade
    perf2Store.status.profitLossPerTrade = profitLossPerTrade
    perf2Store.status.winRate = (profitTrades.length / (tradesSize || 1)) * 100
    perf2Store.status.tradesSize = tradesSize
    perf2Store.status.winTradeCount = profitTrades.length
    perf2Store.status.lossTradeCount = profitLossTrades.length
    perf2Store.status.profitTotalTrade = profitWinTotal + profitLossTotal
    perf2Store.status.maxWinTrade = maxWin
    perf2Store.status.maxLossTrade = maxLoss
    perf2Store.status.riskRatio = riskRatio
    perf2Store.status.startDate = startDate
    perf2Store.status.endDate = endDate
    perf2Store.status.profitSumDataArray = profitSumDataArray
    perf2Store.status.profitDataArray = profitDataArray
    perf2Store.status.profitDetailDataArray = profitDetailDataArray
    perf2Store.status.maxDrawdown = maxDrawdown
    perf2Store.status.tradeRatio = tradeRatio

    perf2Store.private.lastModified = perf2Store.localStorage.getLastModified()

    debugAPI.perf.log(`[摘要] ${perf2Store.updateStats.name}()`, {
      status: { ...perf2Store.status },
    })
  },
  private: {
    lastModified: 0,
  },
  localStorage: {
    /** 新增進出場記錄 */
    addTradeDeal(state: {
      symbol?: string
      interval: string
      timeAt: number
      position: number | string
      price: number | string
      profit: number | string
    }) {
      const symbol = state.symbol || 'null'
      const interval = state.interval || 'null'

      /** E.g. `'TX-1_60_1646876700000'` */
      const key = `${symbol}_${interval}_${state.timeAt}`
      /**
       * - E.g. `'1646873100000_1_17393_0'`
       * - E.g. `'1646876700000_0_17443_10000'`
       * - E.g. `'1646711100000_-1_16768_0'`
       * - E.g. `'1646714700000_0_16718_10000'`
       */
      const data = `${state.timeAt}_${state.position}_${state.price}_${state.profit}`

      sessionStorage.setItem(key, data)

      perf2Store.localStorage.setSymbol(symbol)
      perf2Store.localStorage.setInterval(interval)
      perf2Store.localStorage.setLastModified()
    },
    /** 找進出場記錄 */
    findTradeDeals(query = '') {
      query =
        query || perf2Store.localStorage.getSymbol() + '_' + perf2Store.localStorage.getInterval()

      let i
      const results = []
      for (i in sessionStorage) {
        if (i.match(query) || (!query && typeof i === 'string')) {
          const value = sessionStorage.getItem(i)
          results.push({ key: i, val: value })
        }
      }
      return results
    },
    /** 清除進出場記錄 */
    resetTradeDeals(query = '') {
      query =
        query || perf2Store.localStorage.getSymbol() + '_' + perf2Store.localStorage.getInterval()

      let i
      for (i in sessionStorage) {
        if (i.match(query) || (!query && typeof i === 'string')) {
          sessionStorage.removeItem(i)
        }
      }
    },
    /** 最後更新時間 */
    getLastModified() {
      return parseInt(sessionStorage.getItem(SessionKey.LAST_MODIFIED) || '0')
    },
    /** 最後更新時間 */
    setLastModified() {
      sessionStorage.setItem(SessionKey.LAST_MODIFIED, new Date().getTime().toString())
    },
    /** 圖表目前商品 */
    getSymbol() {
      return sessionStorage.getItem(SessionKey.SYMBOL) || 'null'
    },
    /** 圖表目前商品 */
    setSymbol(symbol?: string) {
      sessionStorage.setItem(SessionKey.SYMBOL, symbol || 'null')
    },
    /** 圖表目前週期 */
    getInterval() {
      return sessionStorage.getItem(SessionKey.INTERVAL)
    },
    /** 圖表目前週期 */
    setInterval(interval?: string) {
      sessionStorage.setItem(SessionKey.INTERVAL, interval || 'null')
    },
  } as const,
})

devtools(perf2Store, 'perf2Store')
