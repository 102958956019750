/** 計算獲利虧損 */
export function getPositionProfit(data: {
  /** 當前K棒持倉狀況 */
  bar0: {
    /** `1` 一單多倉; `-1` 一單空倉 */
    position: number
    priceEntry?: number
    priceExit?: number
  }
  /** 前一根K棒持倉狀況 */
  bar1: {
    /** `1` 一單多倉; `-1` 一單空倉 */
    position: number
    priceEntry?: number
    priceExit?: number
  }
  /**
   * 每點價值
   *
   * - 小台每點 50 新台幣
   * - 大台每點 200 新台幣
   */
  bigPointValue: number
}) {
  /** 多單出場 */
  const longExit = data.bar0.position === 0 && data.bar1.position === 1
  /** 空單出場 */
  const shortExit = data.bar0.position === 0 && data.bar1.position === -1
  /** 空翻多 空單出場 */
  const longRetroflexion = data.bar0.position === 1 && data.bar1.position === -1
  /** 多翻空 多單出場 */
  const shortRetroflexion = data.bar0.position === -1 && data.bar1.position === 1

  /** 多單平倉點數邏輯 */
  const longExitProfit = (data.bar0.priceExit ?? 0) - (data.bar0.priceEntry ?? 0)
  /** 空單平倉點數邏輯 */
  const shortExitProfit = (data.bar0.priceEntry ?? 0) - (data.bar0.priceExit ?? 0)
  /** 空翻多單平倉點數邏輯 */
  const longRetroflexionProfit = (data.bar1.priceEntry ?? 0) - (data.bar0.priceEntry ?? 0)
  /** 多翻空單平倉點數邏輯 */
  const shortRetroflexionProfit = (data.bar0.priceEntry ?? 0) - (data.bar1.priceEntry ?? 0)

  /** 正常出場事件 */
  const defaultExit = longExit || shortExit
  /** 翻單出場事件 */
  const retroflexionExit = longRetroflexion || shortRetroflexion

  const defaultProfit = longExit ? longExitProfit : shortExitProfit

  const retroflexionProfit = shortRetroflexion ? shortRetroflexionProfit : longRetroflexionProfit

  const newEntryProfit = 0

  const profit =
    (defaultExit ? defaultProfit : retroflexionExit ? retroflexionProfit : newEntryProfit) *
    data.bigPointValue

  const price = data.bar0.position !== 0 ? data.bar0.priceEntry : data.bar0.priceExit

  return {
    profit,
    price,
  }
}
