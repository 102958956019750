import { useIntervalNow } from '~/hooks/useIntervalNow'
import { perf2Store } from '~/modules/SDK/Perf/perf2Store'

/**
 * 定時獲取從 indicator 塞進來的交易記錄，並計算其衍生數值，供 react 使用。
 *
 * - 策略績效必備
 * - 只需在最外層放一個，不要重複呼叫
 */
export function usePerf2Connect(
  /**
   * 豪秒
   *
   * - E.g. `1000` 1秒
   * - E.g. `null` 不 render
   */
  interval: null | number,
) {
  useIntervalNow(() => {
    if (!perf2Store.hasNewLastModified()) {
      return
    }

    perf2Store.updateStats()
  }, interval)
}
