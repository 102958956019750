import { memo } from 'react'
import { WatchListWithChart } from '~/modules/symbolQuote/simple'
import { styleds } from './styleds'
import { store } from '~/pages/heineken_template/_private/store'
import { useIntradayPick } from '~/modules/screener/useDatePick'
import FuiButton from '~/pages/heineken_template/components/FuiButton'
import { useVirtualAccountHolding } from '~/modules/virtualExchange/useVirtualExchangeResource'
import { amy_store } from './amy_store'
import { useSnapshot } from 'valtio'
import { css } from '@emotion/react'
import dayAPI from '~/utils/dayAPI'
import { useVirtualHoldingSort } from '~/modules/investment-consultant/signalrQuoteSort/useSignalrSortCondition'
import IntradayTrendChart from '~/modules/trendChart'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { WatchSymbolToggleSingleButton } from '~/pages/heineken_template/_col/col_WatchSymbolToggleSingleButton'
import SymbolHoldingProfitList from '~/modules/investment-consultant/symbolList/list/SymbolHoldingProfitList'
import { useZScorePick } from '~/modules/investment-consultant/stockPickTable/stockPickTable'
import { SegmentedControl } from '@mantine/core'
import { usePercentRangeResource } from '~/modules/screener/containers/useStockScreenerResource'

export const AmyStock_SidePane1 = memo<ReactProps>(function AmyStock_SidePane1() {
  const agentState = useSnapshot(amy_store)
  const charting = useSnapshot(store.charting)

  const holdingType = 'amy_stock2'

  //起始與終止日
  const intraDay = useIntradayPick()
  const userStartDate = dayAPI(intraDay).subtract(45, 'days').format('YYYY-MM-DD')
  const userEndDate = dayAPI(intraDay).subtract(15, 'days').format('YYYY-MM-DD')

  const holding = useVirtualAccountHolding(holdingType)
  const holdingSort = useVirtualHoldingSort(holding ?? [])?.filter(
    agentState.tabs.store.active === '波段持股'
      ? s => s.lastModifiedDatetime > userStartDate
      : s => s.lastModifiedDatetime > userStartDate && s.lastModifiedDatetime < userEndDate,
  )
  const watchListGroup =
    agentState.holdingPositionType === 'long' ? 'amy_stock_long' : 'amy_stock_short'

  /** 持有部位多空篩選 */
  const holdingIsProfitFilter = holdingSort?.filter(
    agentState.holdingPositionType === 'long'
      ? s => s.marketPrice / s.averagePrice > 1
      : s => s.averagePrice / s.marketPrice > 1,
  )

  /** 預設選股 */
  const defaultPosition =
    agentState.holdingPositionType === 'long'
      ? holdingIsProfitFilter.filter(s => s.qty === 1)
      : holdingIsProfitFilter.filter(s => s.qty === -1)

  /** 趨勢 強/弱 */
  const zscoreSymbol = useZScorePick({
    date: intraDay,
    days: 10,
    type: agentState.holdingPositionType === 'long' ? 'strong' : 'weak',
    volume: 50,
    amountGte: 1000,
    itemLimit: 50,
  }).resultSymbol

  /** 價格 */
  const dailyRankSymbol = usePercentRangeResource({
    days: 60,
    status: agentState.holdingPositionType === 'long' ? 'new_high' : 'new_low',
    volume_gte: 50,
    volume_lte: 0,
  }).data?.map(s => s.symbol)

  /** 趨勢與預設 篩選的交集商品 */
  const zscorePosition = defaultPosition.filter(item1 =>
    zscoreSymbol?.some(item2 => item2 === item1.symbol),
  )

  /** 價格與預設 篩選的交集商品 */
  const dailyRankPosition = defaultPosition.filter(item1 =>
    dailyRankSymbol?.some(item2 => item2 === item1.symbol),
  )

  /** 價格+區勢 篩選的交集商品 */
  const allConditionPosition = zscorePosition.filter(item1 =>
    dailyRankSymbol?.some(item2 => item2 === item1.symbol),
  )

  /** 根據用戶篩選邏輯 最終顯示的商品 */
  const result = () => {
    if (agentState.filterType === 'zscore') {
      return zscorePosition
    } else if (agentState.filterType === 'price') {
      return dailyRankPosition
    } else if (agentState.filterType === 'all') {
      return allConditionPosition
    } else {
      return defaultPosition
    }
  }

  return (
    <styleds.Sidebar>
      <styleds.buttonGroupContent>
        <styleds.buttonGroupContent>
          <amy_store.tabs.WithButton to='波段持股'>
            <FuiButton.Display active={true}>金雞精選</FuiButton.Display>
          </amy_store.tabs.WithButton>
          ||
          <amy_store.tabs.WithButton to='自選股'>
            <FuiButton.Display active={agentState.isHoldingSymbol === true}>
              自選股
            </FuiButton.Display>
          </amy_store.tabs.WithButton>
          {/* ||
          <amy_store.tabs.WithButton to='甜蜜股'>
            <FuiButton.Display active={agentState.isHoldingSymbol === true}>
              甜蜜股
            </FuiButton.Display>
          </amy_store.tabs.WithButton> */}
        </styleds.buttonGroupContent>
      </styleds.buttonGroupContent>

      <styleds.buttonGroupContent>
        <styleds.HoldingPosition
          variant='long'
          active={agentState.holdingPositionType === 'long'}
          onClick={() => (amy_store.holdingPositionType = 'long')}
        >
          多方
        </styleds.HoldingPosition>
        ||
        <styleds.HoldingPosition
          variant='short'
          active={agentState.holdingPositionType === 'short'}
          onClick={() => (amy_store.holdingPositionType = 'short')}
        >
          空方
        </styleds.HoldingPosition>
      </styleds.buttonGroupContent>
      <styleds.filterContent>
        <styleds.filterTitle>進階篩選</styleds.filterTitle>
        <SegmentedControl
          css={css`
            width: calc(100% - 68px);
          `}
          radius={4}
          disabled={agentState.tabs.store.active === '自選股'}
          onChange={val => (amy_store.filterType = val)}
          data={[
            { label: '精選', value: 'default' },
            { label: '進階', value: 'price' },
            // { label: '趨勢', value: 'zscore' },
            // { label: '綜合', value: 'all' },
          ]}
        />
      </styleds.filterContent>
      <div
        css={css`
          width: 100%;
          height: calc(100% - 382px);
        `}
      >
        <amy_store.tabs.WithContent for='波段持股'>
          <SymbolHoldingProfitList.DisplayStyled2
            data={result()}
            chart={store.charting}
          />
        </amy_store.tabs.WithContent>
        <amy_store.tabs.WithContent for='自選股'>
          <WatchListWithChart
            groupName={watchListGroup}
            useChart={store.charting}
            showStockFutures={false}
          />
        </amy_store.tabs.WithContent>
        {/* <amy_store.tabs.WithContent for='甜蜜股'>
          <HoldingQuoteListInteractWithChart
            data={sweatPosition}
            chart={store.charting}
            showStockFutures={false}
          />
        </amy_store.tabs.WithContent> */}
      </div>

      <styleds.SymbolBoard
        size='larger'
        css={css`
          height: 248px;
        `}
      >
        <styleds.ButtonGroupContent>
          <WatchSymbolToggleSingleButton
            type='long'
            groupName='amy_stock_long'
          />
          <WatchSymbolToggleSingleButton
            type='short'
            groupName='amy_stock_short'
          />
        </styleds.ButtonGroupContent>
        <TitleSymbolQuote.Default symbol={charting.symbol} />
        <styleds.TrendChartContent>
          <IntradayTrendChart
            symbol={charting.symbol}
            ticksSize={14}
            ticksHeight={20}
            priceTicksMargin={2.5}
            priceTicksSize={14}
          />
        </styleds.TrendChartContent>
      </styleds.SymbolBoard>
    </styleds.Sidebar>
  )
})
